import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// The location data with placeholders for "Mobile", "Store", and "Sticker"
const locations = [
  { name: "AMEERPET", mobile: "1234567890", store: "Store A", sticker: "Sticker 1" },
  { name: "ASRAONAGAR", mobile: "1234567891", store: "Store B", sticker: "Sticker 2" },
  { name: "ATTAPUR", mobile: "1234567892", store: "Store C", sticker: "Sticker 3" },
  { name: "BODUPPAL", mobile: "1234567893", store: "Store D", sticker: "Sticker 4" },
  { name: "CHANDANAGAR", mobile: "1234567894", store: "Store E", sticker: "Sticker 5" },
  { name: "CHINTAL", mobile: "1234567895", store: "Store F", sticker: "Sticker 6" },
  { name: "DILSUKHNAGAR", mobile: "1234567896", store: "Store G", sticker: "Sticker 7" },
  { name: "ECIL", mobile: "1234567897", store: "Store H", sticker: "Sticker 8" },
  { name: "KHARKHANA", mobile: "1234567898", store: "Store I", sticker: "Sticker 9" },
  { name: "KHARMANGHAT", mobile: "1234567899", store: "Store J", sticker: "Sticker 10" },
  { name: "KUKATPALLY", mobile: "1234567800", store: "Store K", sticker: "Sticker 11" },
  { name: "LANGERHOUSE 2", mobile: "1234567801", store: "Store L", sticker: "Sticker 12" },
  { name: "MADHAPUR", mobile: "1234567802", store: "Store M", sticker: "Sticker 13" },
  { name: "MALKAJGIRI", mobile: "1234567803", store: "Store N", sticker: "Sticker 14" },
  { name: "RAMANTHAPUR", mobile: "1234567804", store: "Store O", sticker: "Sticker 15" },
  { name: "RTC X ROAD", mobile: "1234567805", store: "Store P", sticker: "Sticker 16" },
  { name: "S.D.ROAD", mobile: "1234567806", store: "Store Q", sticker: "Sticker 17" },
  { name: "S.R.NAGAR", mobile: "1234567807", store: "Store R", sticker: "Sticker 18" },
  { name: "SANTOSHNAGAR", mobile: "1234567808", store: "Store S", sticker: "Sticker 19" },
  { name: "SAROORNAGAR", mobile: "1234567809", store: "Store T", sticker: "Sticker 20" },
  { name: "SHAMSHABAD", mobile: "1234567810", store: "Store U", sticker: "Sticker 21" },
  { name: "SHAPUR", mobile: "1234567811", store: "Store V", sticker: "Sticker 22" },
  { name: "SUCHITRA", mobile: "1234567812", store: "Store W", sticker: "Sticker 23" },
  { name: "UPPAL", mobile: "1234567813", store: "Store X", sticker: "Sticker 24" },
  { name: "VANASTALIPURAM", mobile: "1234567814", store: "Store Y", sticker: "Sticker 25" }
];



const CustomersTable = () => {
    const [customersData,setCustomersData]=useState([]);

useEffect(()=>{
    getCustomersData()
},[])
const getCustomersData=async()=>{
    try{
    let {data}=await axios('https://dev-services.happimobiles.com/api/pushpa');
    if(data.status) setCustomersData(data.customers)
        
    }
    catch(e){
        toast.error("Network Error")
    }
}
  return (
    <div style={{ backgroundColor: '#fb9013', padding: '20px' }}>
      <h1 style={{ textAlign: 'center', color: '#fff' }}>Customers Table</h1>
      <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ backgroundColor: '#ff7518' }}>
            <th style={{ padding: '10px', color: '#fff', textAlign: 'left' }}>Name</th>
            <th style={{ padding: '10px', color: '#fff', textAlign: 'left' }}>Mobile</th>
            <th style={{ padding: '10px', color: '#fff', textAlign: 'left' }}>Store</th>
            <th style={{ padding: '10px', color: '#fff', textAlign: 'left' }}>Sticker</th>
          </tr>
        </thead>
        <tbody>
          {customersData.map((location, index) => (
            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#f7f7f7' }}>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{location.name}</td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{location.phone}</td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{location.store}</td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{location.sticker}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomersTable;
