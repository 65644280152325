import React, { useEffect, useState } from "react";
import axios from "axios";

//re-usable hook that receives url and returns data and loader status.

const useGetTimeLineDataHook=(url)=>{
    const [data, setData] = useState({loader:true,liveData:[]});
    useEffect(() => {
      getData();
    }, [url]);

    const getData = async () => {
      let response = await axios(url);
      let result = await response;
      setData({loader:false,liveData:result?.data?.data});
    };
    return {data}
}
export default useGetTimeLineDataHook;