import React, { useEffect, useState } from 'react';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import Loader from '../../components/loader/loader';
import TimelineDashboard from '../../components/TimelineDashboard/TimelineDashboard';
import happibg from '../../assets/happibg.jpg';
import '../TimelinePages/Timeline.scss';
import axios from 'axios';
import { Col, Container, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import './aha.scss'



const AhaBidUsers = () => {
    const [data,setData]=useState({loader:true,data:[]});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [showBannerModal,setShowBannerMOdal]=useState(false);
    const [bannerData,setBannerData]=useState({type:null,image_url:null});
    console.log("bannerData",bannerData)


    // console.log("data",data)
    useEffect(()=>{
        getUsersData()
    },[currentPage])

    const getUsersData=async()=>{
        let {data}=await axios(`https://dev-services.happimobiles.com/admin-aha-leads?pageSize=${10}&pageNumber=${currentPage}`);
        if(data.status){
            setTotalPages(data?.meta_data.totalPages)
            setData({loader:false,data:data?.bids})
        }
    }

      
        const getPaginationItems = () => {
          const items = [];
          const maxPagesToShow = 6; // Maximum number of page buttons to display
          const leftDotsThreshold = 3; // Pages before current to show dots
          const rightDotsThreshold = 3; // Pages after current to show dots
      
          // If there are less than or equal to max pages, show all
          if (totalPages <= maxPagesToShow) {
            for (let i = 1; i <= totalPages; i++) {
              items.push(i);
            }
          } else {
            // Always show the first page
            items.push(1);
            
            // Add left dots if necessary
            if (currentPage > leftDotsThreshold + 1) {
              items.push('...');
            }
      
            // Determine which pages to show around the current page
            const startPage = Math.max(2, currentPage - leftDotsThreshold);
            const endPage = Math.min(totalPages - 1, currentPage + rightDotsThreshold);
      
            for (let i = startPage; i <= endPage; i++) {
              items.push(i);
            }
      
            // Add right dots if necessary
            if (currentPage < totalPages - rightDotsThreshold) {
              items.push('...');
            }
      
            // Always show the last page
            items.push(totalPages);
          }
      
          return items;
        };
    
        const submitBanner = async()=>{
          if(bannerData.type==null)  return toast.error('Please Select Device type');
          if(bannerData.image_url==null)  return  toast.error('Please Add Image url');
          if(bannerData.image_url=="")  return  toast.error('Please Add Image url')

          if(bannerData.type!=null&&bannerData.image_url!=null){
            try{
              let {data}=await axios.post('https://dev-services.happimobiles.com/aha-banners',bannerData,{headers:{"content-type":"application/json"}});
            if(data.status){
              handleBannerClose()
              toast.success("Banner Added Successfully")}
            }
            catch(e){
              console.log("e",e.message)
            }
          }

         
        }
        const handleBannerClose=()=>{
          setBannerData({type:null,image_url:null})
          setShowBannerMOdal(false)
        }
    
  return (
      <>
       <div className='previous-page container mt-5 d-flex containerbg align-items-center'>
        <img src={happibg} height={70} width={180}/>
        <h4 className='title m-0'>AHA Bid Customers List</h4>
        <div className='ms-auto d-flex gap-2'>
        <h6 className='dwn' onClick={()=>setShowBannerMOdal(true)}>Add Banners</h6>
        <h6 className='dwn'><a href='https://dev-services.happimobiles.com/aha-leads-csv-download' download>Download</a></h6>
       
        </div>
      
      </div>
     
      {data?.loader?<Loader/>:data.data.length>0?
      <div className="timelinedashboard">
      <Container>
        <Row className="mt-3">
       
          <Col>
            <div className="fixTableHead">
              <Table responsive striped bordered hover>
                <thead>
                  <tr className="th-row">
                    <th>Name</th>
                    <th>Phone Number</th>

                    <th>Bid Amount</th>
                    <th>Location</th>
                    <th>Created On</th>
                  
                  </tr>
                </thead>
                <tbody>
                  {data?.data.map((item, index) => {
                    return (
                      <tr key={index} className="tablerow">
                        <td className='fs-6 text-capitalize' style={{fontWeight:400}}>
                            {item?.name}
                          </td>
                          <td>
                            {
                                item?.phone
                            }
                        </td>
                        <td className='fs-6 text-capitalize' style={{fontWeight:400}}>
                       {item?.amount}
                         </td>
                       
                       
                        <td className='fs-6 text-capitalize' style={{fontWeight:400}}>
                        {item?.location}
                          </td>
                        <td>
                         {
                            moment(item.updated_at).format('lll')
                         }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className='d-flex justify-content-center'>
            <Pagination>
               
            {
                getPaginationItems().map((item,index)=> <Pagination.Item  key={index} active={item === currentPage} onClick={()=>{setData(prev=>({...prev,loader:true}));setCurrentPage(item)}} disabled={item === '...'}>{item}</Pagination.Item>)
            }
           
            </Pagination>
            </div>
          </Col>
        </Row>
      </Container>
      </div>
      :<NoDataFound/>} 
       <Modal show={showBannerModal} onHide={handleBannerClose}
       centered 
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        >
        <Modal.Header closeButton>
          <Modal.Title>Add Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Select aria-label="Default select example" onChange={(e)=>setBannerData(prev=>({...prev,type:e.target.value}))}>
      <option  >Select Device Type</option>
      <option value="desktop" >Desktop</option>
      <option value="mobile">Mobile</option>
    </Form.Select>
        <Form>
      
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Control type="text" placeholder="Banner url" onChange={(e)=>setBannerData(prev=>({...prev,image_url:e.target.value}))} />
      </Form.Group>
      </Form>
      <div className='bshadow m-0 mt-3'>
        <h6 className='m-0 '>Note:</h6>
        <p className='fsize m-0 p-0'>desktop banner size must be 1600 x 422</p>
        <p className='fsize m-0 p-0'>mobile  banner size must be 800 x 1120</p>
      </div>
          </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleBannerClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submitBanner}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AhaBidUsers