import React, { useState } from 'react'
import TimelineDashboard from '../../components/TimelineDashboard/TimelineDashboard';
import Loader from '../../components/loader/loader';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import useGetTimeLineDataHook from '../../CustomHooks/useGetTimeLineDataHook';
import DatePicker from "react-datepicker";
import "./Timeline.scss";
import happibg from '../../assets/happibg.jpg'
import { FaCalendarAlt } from "react-icons/fa";
import moment from 'moment';



//page for showing perviousday timeline data receives data by custom hook and sends those data to TimeLineDashboard Component and displays it.
//TimeLineDashboard receives data through props and displays it.
//useGetTimelineDataHook custom hook which takes url and returns data and loader status.

const PreviousDayTimeLine = () => {
  const [startDate, setStartDate] = useState(new Date());
  const {data}=useGetTimeLineDataHook(`https://tm-api.happimobiles.com/api/stocktransfer/getSTNStatusTimes?value=manual&date=${startDate.toISOString()}`);
  return (
    <>
   
       <div className='previous-page d-flex containerbg align-items-center justify-content-between'>
        <div className='d-flex gap-2 align-items-center justify-content-between'>
        <img src={happibg} height={50} width={120}/>
        <div className='d-flex w-100 align-items-center mt-2'>
        <div className='datepicker-parent mr-5'>
          <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} 
            maxDate={new Date()}
            />
            <FaCalendarAlt  className='svg1'/>
        </div>
        </div>
        </div>
        <h4 className='title'>STN TimeLine Report</h4>
        <div>
       
        </div>
      
        
      </div>
     
      {data?.loader?<Loader/>:data?.liveData?.length>0?<TimelineDashboard data={data?.liveData}/>:<NoDataFound/>} 
    </>
  
  )
}

export default PreviousDayTimeLine