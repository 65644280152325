import React, { useEffect, useState, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import "react-toastify/dist/ReactToastify.css";
import "./Profitability.scss";
import { getBrands, getModals, getStores } from "../../redux/reducers/rules";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "react-vertical-timeline-component/style.min.css";
import Loader from "../../components/loader/loader";
// import Loader from "../../components/flagloader/Loader";
import Table from "react-bootstrap/Table";
import {
  setActiveTab,
  getMTD,
  getFTD,
  getMonthData,
  setModalTab,
  getProducts,
  getProfitsheets,
  getTransactions,
  getBrandsandModels,
  getAccessories,
  happicareList,
  getHappicare,
  setSearchProfitsheets,
  getAllProfitsheets,
  getAllASM,
  getAllStores,
  postCustomerDetails,
  getMTDRanking,
  getFTDRanking,
  getMonthDataRanking,
  getPDaysLdays,
  getAffordabilityData,
  getMissedCustomersData,
  getSyncTime,
  setSelectedDeviceItem,
  setEmpId,
  setAddedAccessoriesItem,
  setMissiedCustomerData
} from "../../redux/reducers/profitability";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { IoDocumentTextSharp } from "react-icons/io5";
import DataGrid from "react-data-grid";
import { Watermark } from "@hirohe/react-watermark";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import discount from "../../assets/icons8-discount.gif";
import expenses from "../../assets/expenses.png";
import grossprofit from "../../assets/grossprofit.png";
import netprofit from "../../assets/netprofit.jpg";
import gstimage from "../../assets/gst.png";
import profit1 from "../../assets/profit2.png";
import lost from "../../assets/loss.jpg";
import { debounce } from "lodash";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { RxCrossCircled } from "react-icons/rx";
// import netprofit from "../../assets/netprofit.jpg"
import { FaSortAmountDownAlt } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import ProfitRanking from "./ProfitRanking";
import { useNavigate } from "react-router-dom";

export default function Profitability() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const [modalShow, setModalShow] = React.useState(false);
  //ravi code starts here
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  // Getting the query parameters with default values

  let store_id = params.get("store_id") || "defaultId";
  let approver = params.get("approver") || "defaultId";
  let phoneNumber = params.get("phoneNumber") || "defaultId";
  let empId = params.get("emp_id") || "defaultId";
  let v = params.get("v") || "v0";
  let activeTab=params.get('tab');
  console.log("tabName",activeTab)
  if (v == "v0") {
    window.location.replace(window.location.href + "&v=v1");
  }
  if (approver == "Store") approver = "store";
  const storeList = useSelector((state) => state.rules.storeList);
  const modalList = useSelector((state) => state.rules.modalList);
  // const brandList = useSelector((state) => state.rules.brandList);
  // const activeTab = useSelector((state) => state.profitability.activeTab);
  console.log("activeTabactiveTab",activeTab)
  const lossDaysProfitDays = useSelector(
    (state) => state.profitability.lDaysPDays
  );
  const allTransferList = useSelector(
    (state) => state.stocktransfer.allTransferList
  );
  const trackingDetails = useSelector(
    (state) => state.stocktransfer.trackingDetails
  );
  const approve_response = useSelector(
    (state) => state.stocktransfer.approve_response
  );
  const loader = useSelector((state) => state.stocktransfer.isLoader);
  const [selectedBrandModelList, setSelectedBrandModelList] =
    useState(modalList);
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedModal, setSelectedModal] = useState();
  const [selectedStore, setSelectedStore] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [rankingshowFilter, setRankingShowFilter] = useState(false);
  const [show, setShow] = useState(false);
  const mtdData = useSelector((state) => state.profitability.mtdData);
  const ftdData = useSelector((state) => state.profitability.ftdData);
  const monthData = useSelector((state) => state.profitability.monthData);
  const [qtModalData, setQTModalData] = useState([]);
  const [showQTModal, setShowQTModal] = useState(false);
  const [tabDataLoader, setTabDataLoader] = useState(true);
  const [customFilterLoader, setCustomFilterLoader] = useState(false);

  //RANKING STATES
  const mtdRankingData = useSelector(
    (state) => state.profitability.mtdRankingData
  );
  const ftdRankingData = useSelector(
    (state) => state.profitability.ftdRankingData
  );
  const monthRankingData = useSelector(
    (state) => state.profitability.monthRankingData
  );
  const [rankingTab, setRankingTab] = useState("PROFIT");
  const [affortablePaymentModalData, setAffortablePaymentModalData] = useState({
    data: [],
    isAffortableData: false,
    title: "",
    isPaymentData: false,
    others:{}
  });
  const [showAffortablePaymentModal, setshowAffortablePaymentModal] =
    useState(false);

  const activereportTable = useSelector(
    (state) => state.profitability.activereportTable
  );
  const productList = useSelector((state) => state.profitability.productList);
  const profitsheets = useSelector((state) => state.profitability.profitsheets);
  const availableASM = useSelector((state) => state.profitability.asm);
  const stores = useSelector((state) => state.profitability.stores);
  const allProfitsheets = useSelector(
    (state) => state.profitability.allProfitsheets
  );
  const transactionsData = useSelector(
    (state) => state.profitability.transactionsData
  );
  const allAccesseries = useSelector(
    (state) => state.profitability.allAccesseries
  );
  const happicareList = useSelector(
    (state) => state.profitability.happicareList
  );
  const [allBrands, setAllbrands] = useState();
  const allbrands = useSelector((state) => state.profitability.allbrands);
  const [selectedAccessories, setSelectedAccessories] =
    useState(allAccesseries);
  const [selectedHappicare, setSelectedHappicare] = useState(happicareList);
  const todayDate = new Date();
  todayDate.setDate(todayDate.getDate() - 1);
  const [customStartDate, setCustomStartDate] = useState(
    moment(new Date(todayDate)).format("YYYY/MM/DD")
  );
  const [customEndDate, setCustomEndDate] = useState(
    moment(new Date(todayDate)).format("YYYY/MM/DD")
  );
  const [transactionStartDate, setTransactionStartDate] = useState(
    moment(new Date()).format("YYYY/MM/DD")
  );
  const [transactionEndDate, setTransactionEndDate] = useState(
    moment(new Date()).format("YYYY/MM/DD")
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [profitSheetSearch, setProfitSheetSearch] = useState("");
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [profit, setProfit] = useState(0);
  const [discountValue, setDiscountValue] = useState();
  const [addedAccessories, setAddedAccessories] = useState([]);
  const [lossGainModal, setLossGainModal] = useState(false);
  const [modalTitle, setModalTitle] = useState({});
  const [totalmop, setTotalmop] = useState(0);
  const [globalSearchResults, setGlobalSearchResults] = useState([]);
  const [maxDiscountValue, setMaxDicountValue] = useState();
  const [HCDiscountValue, setHCMaxDicountValue] = useState({
    MOP: 500,
    GP: 50,
  });
  const [selectedStatehead, setSelectedStatehead] = useState();
  const [selectedASM, setSelectedASM] = useState();
  const [customerDetailsModel, setCustomerDetailsModel] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    qty: "",
    name: "",
    number: "",
  });
  const [customerSelectedItem, setCustomerSelectedItem] = useState();
  const [affordabilityftdData, setAffordabilityFtdData] = useState({
    loader: true,
    payment: {},
    affordability: {},
    others:{}
  });
  const [affordabilitymtdData, setAffordabilityMtdData] = useState({
    loader: true,
    payment: {},
    affordability: {},
    others:{}
  });
  console.log("affordabilitymtdData",affordabilitymtdData)
  const [affordabilityMonthData, setAffordabilityMonthData] = useState({
    loader: true,
    payment: {},
    affordability: {},
    others:{}
  });
  const [missedCustomerFtdDetails, setMissedCustomerFtdDetails] = useState({});
  const [missedCustomerMtdDetails, setMissedCustomerMtdDetails] = useState({});
  const [lastSyncTime,setLastSyncTime]=useState({last_sync_time:"3 minutes Ago"})
  const [missedCustomerMonthDetails, setMissedCustomerMonthDetails] = useState(
    {}
  );
  console.log("missedCustomerMonthDetails",missedCustomerMonthDetails)
  const [missedCustomerData, setMissedCustmerData] = useState({
    showModal: false,
    data: [],
  });
  // console.log("missedCustomerData",missedCustomerData)

  const percentage = 66;
  const handleClose = () => {
    clearSearch();
    setDiscountValue();
    setShow(false);
    sendMessageToReactNative(false)
    
  };
  const handleShow = () => setShow(true);
  const columns = [
    { key: "item", name: "Item" },
    { key: "inr_mop", name: "MOP" },
    { key: "inr_gp", name: "GP" },
  ];

  const columns1 = [
    { key: "invoice", name: "INVOICE" },
    { key: "item", name: "ITEM" },
    { key: "mop", name: "MOP" },
    { key: "gp", name: "G" },
    { key: "total_discount", name: "D" },
    { key: "gst", name: "T" },
    { key: "net_profit", name: "P" },
  ];

  let access = true;

  //ravi code ends here
  //ravi code

  const statusOptions = [
    { value: "APPROVED", label: "APPROVED" },
    { value: "REJECTED", label: "REJECTED" },
    { value: "MY_APPROVAL", label: "TO BE APPROVED" },
  ];


  useEffect(()=>{
    handleSyncTimeData()
    window.addEventListener('message', (event) => {
      console.log('Received message from React Native:', event.data);
      // Optionally, handle or display the message
      if(event.data=="HideModal"){
        setShow(false)
      }
      setShow(false)
    });
  
  },[])


  function sendMessageToReactNative(message) {
    window.ReactNativeWebView?.postMessage(message);
  }

  const handleSyncTimeData=()=>{
    dispatch(getSyncTime()).then(res=>setLastSyncTime(res?.payload?.[0]))
  }
  useEffect(() => {
    if (approver == "asm" || approver == "statehead") {
      dispatch(getAllASM(phoneNumber));
    }

    // dispatch(getAllStores(selectedData))

    dispatch(getAllProfitsheets(store_id));
    // dispatch(getAccessories()).then((ress) => {
    //   let acceroies = [];
    //   ress?.payload?.map((item) => {
    //     acceroies.push({
    //       label: item.product_name,
    //       value: item.product_name,
    //     });
    //   });
    //   setSelectedAccessories(acceroies);
    // });
    dispatch(getHappicare());
    dispatch(getBrandsandModels()).then((eachItem) => {
      let brandsList = [];
      let modelList = [];
      eachItem?.payload?.map((item) => {
        brandsList.push({
          label: item.brand_name,
          value: item.brand_name,
        });
        setAllbrands(brandsList);
        modelList.push({
          label: item.item_name,
          value: item.item_name,
        });
        setSelectedBrandModelList(modelList);
      });
    });
    // dispatch(getBrands()).then((res) => {
    //   let brandsList = [];
    //   res?.payload?.forEach((eachItem) => {
    //     brandsList.push(eachItem.value);
    //   });
    //   dispatch(getModals({ brandName: brandsList })).then((modals) => {
    //     setSelectedBrandModelList(modals.payload)
    //   });
    // });
  }, [selectedStatehead, selectedASM, selectedStore]);
  useEffect(() => {
    let selectedData = {
      stateHead: selectedStatehead,
      asm: selectedASM,
      phone: phoneNumber,
      role: approver,
      store_code: store_id,
    };

    dispatch(getAllStores(selectedData)).then((allStores) => {
      let selectedroles = {};
      if (selectedStatehead == undefined) {
        if (allStores?.payload?.stateHeads?.length == 1) {
          selectedroles["statehead"] = allStores?.payload?.stateHeads[0].value;
        } else {
          selectedroles["statehead"] = 0;
        }
      } else {
        selectedroles["statehead"] = selectedStatehead?.value;
      }

      if (selectedASM == undefined) {
        if (allStores?.payload?.asms?.length == 1) {
          selectedroles["asm"] = allStores?.payload?.asms[0].value;
        } else {
          selectedroles["asm"] = 0;
        }
      } else {
        selectedroles["asm"] = selectedASM?.value;
      }

      if (selectedStore == undefined) {
        if (allStores?.payload?.stores?.length == 1) {
          selectedroles["store"] = allStores?.payload?.stores[0].value;
        } else {
          selectedroles["store"] = 0;
        }
      } else {
        selectedroles["store"] = selectedStore?.value;
      }
      let storeCode = store_id;
      if (store_id == "defaultId") {
        storeCode = 0;
      }
      let pDaysLDays_payload = {
        statehead: selectedStatehead || 0,
        asm: selectedASM || 0,
        store: storeCode,
      };

      // let storeDetailsPayload = {
      //   statehead: selectedStatehead || 0,
      //   asm: selectedASM || 0,
      //   store: selectedroles.store,
      // };
      let storeDetailsPayload = selectedroles
      let ftd_payload = { type: "ftd", pay: storeDetailsPayload };
      let mtd_payload = { type: "mtd", pay: storeDetailsPayload };
      let affordabilityPayload = {
        pay: storeDetailsPayload,
        dates: {
          from: customStartDate,
          to: customEndDate,
        },
      };
      dispatch(getAffordabilityData(ftd_payload)).then((res) => {
        if (res?.payload?.status) {
          setAffordabilityFtdData({
            loader: false,
            affordability: res?.payload?.affordability,
            payment: res?.payload?.paymet_mode,
            others:res?.payload?.others

          });
        }
      });
      dispatch(getAffordabilityData(mtd_payload)).then((res) => {
        if (res?.payload?.status) {
          setAffordabilityMtdData({
            loader: false,
            affordability: res?.payload?.affordability,
            payment: res?.payload?.paymet_mode,
            others:res?.payload?.others
          });
        }
      });
      dispatch(getAffordabilityData(affordabilityPayload)).then((res) => {
        if (res?.payload?.status) {
          setAffordabilityMonthData({
            loader: false,
            affordability: res?.payload?.affordability,
            payment: res?.payload?.paymet_mode,
            others:res?.payload?.others
          });
        }
      });

      dispatch(getMissedCustomersData(ftd_payload)).then((res) => {
        if (res?.payload?.status) {
          setMissedCustomerFtdDetails({
            loader: false,
            data: res?.payload?.data,
          });
        }
      });
      dispatch(getMissedCustomersData(mtd_payload)).then((res) => {
        if (res?.payload?.status) {
          setMissedCustomerMtdDetails({
            loader: false,
            data: res?.payload?.data,
          });
        }
      });
      dispatch(getMissedCustomersData(affordabilityPayload)).then((res) => {
        if (res?.payload?.status) {
          setMissedCustomerMonthDetails({
            loader: false,
            data: res?.payload?.data,
          });
        }
      });

      let lDaysPDaysRes;
      if (activeTab == "MTD") {
        lDaysPDaysRes = dispatch(
          getPDaysLdays({
            payload: selectedroles,
            type: activeTab.toLowerCase(),
          })
        );
      }
      if (activeTab == "CUSTOM") {
        lDaysPDaysRes = dispatch(
          getPDaysLdays({
            payload: selectedroles,
            type: activeTab.toLowerCase(),
            from: customStartDate,
            to: customEndDate,
          })
        );
      }

      let getMtdRes = dispatch(
        getMTD({ roles: selectedroles, type: "mtd", phone: phoneNumber })
      );
      let getFtdRes = dispatch(
        getFTD({ roles: selectedroles, type: "ftd", phone: phoneNumber })
      );

      let monthDataRes;
      let monthDataRankingRes;
      if (customStartDate && customEndDate) {
        monthDataRes = dispatch(
          getMonthData({
            roles: selectedroles,
            from: customStartDate,
            to: customEndDate,
            phone: phoneNumber,
          })
        );

        monthDataRankingRes = dispatch(
          getMonthDataRanking({
            roles: selectedroles,
            from: customStartDate,
            to: customEndDate,
            phone: phoneNumber,
          })
        );
      }
      Promise.all([lDaysPDaysRes, getMtdRes, getFtdRes, monthDataRes]).then(
        (res) => {
          setTabDataLoader(false);
        }
      );

      //RANKING API'S
      dispatch(
        getMTDRanking({ roles: selectedroles, type: "mtd", phone: phoneNumber })
      );
      dispatch(
        getFTDRanking({ roles: selectedroles, type: "ftd", phone: phoneNumber })
      );

      if (customFilterLoader) {
        Promise.all([monthDataRankingRes, monthDataRes, lDaysPDaysRes]).then(
          (res) => setCustomFilterLoader(false)
        );
      }

      dispatch(
        getTransactions({
          roles: selectedroles,
          from: transactionStartDate,
          to: transactionEndDate,
        })
      );
    });
  
  }, [
    selectedStore,
    selectedASM,
    selectedStatehead,
    transactionStartDate,
    transactionEndDate,
    customStartDate,
    customEndDate,
    activeTab,
  ]);
  const handleSelectedBrand = (brand) => {
    setSelectedBrand(brand);
    const result = allbrands?.filter((word) => word.brand_name == brand?.value);
    let selectedModels = [];
    result?.forEach((eachmodel) => {
      selectedModels.push({
        label: eachmodel.item_name,
        value: eachmodel.item_name,
      });
    });

    setSelectedBrandModelList(selectedModels);
  };
  const handleSelectedModal = (modal) => {
    setSelectedModal(modal);
  };
  const handleSelectedStore = (store) => {
    setTabDataLoader(true);
    setSelectedStore(store);
  };
  const handleSelectedStatehead = (item) => {
    setTabDataLoader(true);
    setSelectedStatehead(item);
  };
  const handleSelectedASM = (item) => {
    setTabDataLoader(true);
    setSelectedASM(item);
  };
  useEffect(() => {
    let body = {
      brand: selectedBrand,
      model: selectedModal,
      store_id: [store_id],
      search: profitSheetSearch,
    };
    if (approver == "asm" || approver == "statehead") {
      if (selectedStore != undefined && selectedStore.value != "All") {
        body.store_id = selectedStore?.value;
        dispatch(getProfitsheets(body));
      } else {
        let allstoreIds = [];
        availableASM?.length > 0 &&
          availableASM.forEach((eachstore) => {
            if (eachstore.value != "All") allstoreIds.push(eachstore.value);
          });
        body.store_id = allstoreIds;
        dispatch(getProfitsheets(body));
      }
    } else if (approver == "Corporate") {
      if (selectedStore != undefined && selectedStore.value != "All") {
        body.store_id = [selectedStore?.value];
        dispatch(getProfitsheets(body));
      } else {
        let allstoreIds = [];
        stores?.length > 0 &&
          stores.forEach((eachstore) => {
            if (eachstore.value != "All") allstoreIds.push(eachstore.value);
          });
        body.store_id = allstoreIds;
        dispatch(getProfitsheets(body));
      }
    } else {
      dispatch(getProfitsheets(body));
    }
  }, [
    selectedBrand,
    selectedModal,
    selectedStore,
    profitSheetSearch,
    availableASM,
    stores,
  ]);
  const gapColor = (value, type) => {
    let result = Math.sign(value);
    return (
      <>
        {type == "Flashgard" ||
        type == "Mobiles" ||
        type == "featureMobiles" ? (
          <div style={{ color: result != 1 ? "red" : "green" }}>
            {new Intl.NumberFormat("en-IN").format(value)}
          </div>
        ) : (
          <div style={{ color: result != 1 ? "red" : "green" }}>
            &#8377;{new Intl.NumberFormat("en-IN").format(value)}
          </div>
        )}
      </>
    );
  };

  const ShowDataModel = (cardname) => {
    let daata;
    if (activeTab == "MTD") {
      daata = mtdRankingData;
    }
    if (activeTab == "FTD") {
      daata = ftdRankingData;
    }
    if (activeTab == "CUSTOM") {
      daata = monthRankingData;
    }
    if (cardname == "P") {
      setModalShow(true);
      navigate("/profitranking", { state: { data: daata } });
    }
    if (cardname == "L Days") {
      setLossGainModal(true);
      setModalTitle({
        title: "Loss Days",
        data: lossDaysProfitDays.loss.dates,
      });
    }
    if (cardname == "P Days") {
      setLossGainModal(true);
      setModalTitle({
        title: "Profit Days",
        data: lossDaysProfitDays.gain.dates,
      });
    }
  };
  const box = (cardname, image, value) => {
    return (
      <>
        <Col xs={cardname == "P" ? 12 : 6} className="mb-2">
          <Card
            className={cardname == "P" ? "p-3 profit" : "p-3"}
            onClick={() => ShowDataModel(cardname)}
          >
            <div className="d-flex align-items-center gap-3 iconcard">
              <div className="img">
                <img
                  src={image}
                  className={cardname == "G" ? "gross" : "remaining"}
                />
              </div>
              <p
                className={
                  cardname == "P"
                    ? "m-0 text-break d-flex flex-column profitText"
                    : "m-0 text-break d-flex flex-column"
                }
              >
                {cardname}
              </p>
              {cardname == "P" ? (
                <span
                  className="rupees m-0"
                  style={{
                    paddingLeft: "31px !important",
                    fontSize: "1.6rem",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  {gapColor(value)}{" "}
                </span>
              ) : (
                ""
              )}
            </div>
            <p className="rupees m-0">
              {cardname != "P" &&
                cardname != "L Days" &&
                cardname != "P Days" && (
                  <span>
                    &#8377; {new Intl.NumberFormat("en-IN").format(value)}
                  </span>
                )}
              {cardname == "L Days" && lossDaysProfitDays?.loss?.days}
              {cardname == "P Days" && lossDaysProfitDays?.gain?.days}
            </p>
          </Card>
        </Col>
      </>
    );
  };
  const profitabitityboxes = (gross, disco, expense, netprof, gst) => {
    return (
      <>
        <Row className="mb-2  position-relative gx-2 tabprice-cards">
          {box("G", netprofit, gross)}
          {box("D", discount, disco)}
          {box("E", expenses, expense)}
          {box("T", gstimage, gst)}
          {(activeTab == "MTD" || activeTab == "CUSTOM") &&
            box("P Days", profit1, gst, true)}
          {(activeTab == "MTD" || activeTab == "CUSTOM") &&
            box("L Days", lost, gst, true)}
        </Row>
        <Row className=" z-0 position-relative">
          {box("P", grossprofit, netprof)}
        </Row>
      </>
    );
  };

  // Function to handle changes in the search input
  const handleSearchChange = (value) => {
    setSearchTerm(value);

    // Filter the data based on the search term
    if (value?.length != 0) {
      const filteredData = allProfitsheets?.filter((item) =>
        item.item.toLowerCase().includes(value?.toLowerCase())
      );
      setSearchResults(filteredData);
    } else {
      setSearchResults([]);
    }
  };
  const clearSearch = () => {
    setSearchTerm("");
    setSearchResults([]);
  };
  // Function to handle changes in the search input
  const handleProfitsheetsGlobalSearch = (value) => {
    setProfitSheetSearch(value);
    if (value?.length >= 3) {
      const filteredData = profitsheets?.filter((item) =>
        item.item.toLowerCase().includes(value?.toLowerCase())
      );
      setGlobalSearchResults(filteredData);
    }
    if (value?.length == 0) {
      setGlobalSearchResults([]);
    }
  };

  const handleCheckboxChange = (e) => {
    let newValue = e.target._wrapperState.initialValue;
    setAddedAccessories((prevValues) => {
      let exists = prevValues.some((obj) => obj.item == newValue.item);
      if (!exists) {
        return [...prevValues, newValue];
      } else {
        return prevValues.filter((val) => val.item !== newValue.item);
      }
    });
  };
  const check = (value) => {
    const isChecked = addedAccessories.some(
      (item) => item?.item == value?.item
    );
    return isChecked;
  };
  useEffect(() => {
    let totalprofit = 0;
    let totalmop = 0;
    addedAccessories?.length > 0 &&
      addedAccessories?.forEach((each) => {
        let result = Math.sign(parseInt(each.gp));
        let mopresult = Math.sign(parseInt(each.mop));
        if (result == 1) {
          totalprofit = totalprofit + parseInt(each.gp);
        } else {
          totalprofit = totalprofit - parseInt(each.gp);
        }
        if (mopresult == 1) {
          totalmop = totalmop + parseInt(each.mop);
        } else {
          totalmop = totalmop - parseInt(each.mop);
        }
      });
    if (discountValue == undefined) {
      totalprofit = parseInt(totalprofit) - 0;
      totalmop = parseInt(totalmop) - 0;
    } else {
      totalprofit = parseInt(totalprofit) - parseInt(discountValue);
      totalmop = parseInt(totalmop) - parseInt(discountValue);
    }
    setProfit(totalprofit);
    setTotalmop(totalmop);
  }, [addedAccessories, discountValue]);

  const handletransChange = ([newStartDate, newEndDate]) => {
    setTransactionStartDate(newStartDate);
    setTransactionEndDate(newEndDate);
  };

  const handleChange = ([newStartDate, newEndDate]) => {
    setCustomStartDate(newStartDate);
    setCustomEndDate(newEndDate);
  };

  const discountCalculation = (item) => {
    setMaxDicountValue(Math.trunc(item.mop * 0.02));
    let HC_MOP =
      item.mop < 70000
        ? Math.trunc(item.mop * 0.11)
        : Math.trunc(item.mop * 0.08);

    let HC_GP =
      item.mop < 70000 ? Math.trunc(HC_MOP * 1) : Math.trunc(HC_MOP * 1);
    setHCMaxDicountValue({ MOP: HC_MOP, GP: HC_GP });
    let selectedItems = [];
    let scratchgard = allProfitsheets?.find(
      (element) => element?.item == "SCRATCHGARD DEMAND VISTA FILM"
    );
    let combo = allProfitsheets?.find((element) => element?.item == "COMBO 2");
    selectedItems.push(item);
    selectedItems.push(scratchgard);
    selectedItems.push(combo);
    selectedItems.push({
      item: "HAPPI CARE",
      inr_gp: HC_GP,
      inr_mop: HC_MOP,
      gp: HC_GP,
      mop: HC_MOP,
    });
    setAddedAccessories(selectedItems);
    setSelectedDevice(selectedItems);
    dispatch(setSelectedDeviceItem(selectedItems));
    dispatch(setAddedAccessoriesItem(selectedItems))
    dispatch(setEmpId(empId))
    navigate('/profitDetials');
    sendMessageToReactNative("navigating")

    // setShow(true);
    // sendMessageToReactNative(true)

  };
  const addItem = (item) => {
    setSelectedDevice((prevValues) => {
      let exists = selectedDevice.some((obj) => obj.item == item.item);
      if (!exists) {
        return [...prevValues, item];
      } else {
        return prevValues;
      }
    });
  };
  const checkicon = (item) => {
    let checkvalue =
      selectedDevice?.length > 0 &&
      selectedDevice?.some((device) => device.item == item.item);
    return (
      <>
        {checkvalue ? (
          <div style={{ color: "rgba(250, 144, 18, 0.66)" }}>
            {" "}
            <FiMinusCircle />{" "}
          </div>
        ) : (
          <div style={{ color: "rgba(250, 144, 18, 0.66)" }}>
            {" "}
            <FiPlusCircle onClick={() => addItem(item)} />{" "}
          </div>
        )}
      </>
    );
  };

  //close customer details model
  const closeCustomerDetailsModel = () => {
    setCustomerDetailsModel(false);
  };

  //open customer details model
  const openCustomerDetailsPage = (item) => {
    setCustomerSelectedItem(item);
    setCustomerDetailsModel(true);
  };

  const submitCustomerDetails = () => {
    let body = {
      branch_code: store_id,
      item_name: customerSelectedItem?.item_name,
      brand_name: customerSelectedItem?.brand_name,
      customer_name: customerDetails.name,
      customer_mobile: customerDetails.number,
      qty: customerDetails.qty,
      mop: customerSelectedItem.mop,
      gp: customerSelectedItem.gp,
    };
    dispatch(postCustomerDetails(body)).then((res) => {
      setCustomerDetailsModel(false);
    });
  };
  return (
    <>
      {access ? (
        <>
          <Watermark
            text={`-${empId}-`}
            textSize={18}
            textColor="#000000"
            lineHeight="1.5rem"
            opacity={0.09}
          >
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg={3}>
                  <section className="profitability">
                    <Row>
                      <Col lg={12}>
                        <div className="d-flex align-items-center justify-content-between pt-2">
                          {approver == "store" ? (
                            <h3 className="m-0 fs-5">{` ${
                              selectedStore == undefined
                                ? "All"
                                : selectedStore?.label
                            }`}</h3>
                          ) : (
                            <h3 className="m-0 fs-5">{`${
                              selectedStatehead == undefined
                                ? stores?.stateHeads?.[0]?.label
                                : selectedStatehead?.label
                            } > ${
                              selectedASM == undefined
                                ? stores?.asms?.[0]?.label
                                : selectedASM?.label
                            } > ${
                              selectedStore == undefined
                                ? "All"
                                : selectedStore?.label
                            }`}</h3>
                          )}
                          <div
                            className="filter-icon"
                            onClick={() => setShowFilter(!showFilter)}
                          >
                            <FiFilter />
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                      {showFilter && stores?.stateHeads.length > 0 && (
                        <div className="d-flex align-items-center mt-2 mt-sm-2 mb-1">
                          <label className="state-head-label">
                            State Head :
                          </label>
                          <Select
                            defaultValue={stores?.stateHeads[0]}
                            options={stores?.stateHeads}
                            onChange={handleSelectedStatehead}
                            value={selectedStatehead}
                            placeholder={"select statehead..."}
                            isClearable={true}
                            className="w-100"
                          />
                        </div>
                    )}
                      </Col>
                      <Col lg={12}>
                    {showFilter && stores?.asms.length > 0 && (
                        <div className="d-flex align-items-start mt-2 mt-sm-2 mb-1">
                          <label className="state-head-label">ASM :</label>
                          <Select
                            defaultValue={stores?.asms[0]}
                            options={stores?.asms}
                            onChange={handleSelectedASM}
                            value={selectedASM}
                            placeholder={"select ASM..."}
                            isClearable={true}
                            className="w-100"
                          />
                        </div>
                    )}
                      </Col>
                      <Col lg={12}>
                    {showFilter && stores?.stores.length > 0 && (
                        <div className="d-flex align-items-start mt-2 mt-sm-2 mb-1">
                          <label className="state-head-label">Stores:</label>
                          <Select
                            defaultValue={stores?.stores[0]}
                            options={stores?.stores.filter(
                              (item) => item?.label != "HIMAYATHNAGAR"
                            )}
                            onChange={handleSelectedStore}
                            value={selectedStore}
                            placeholder={"select store..."}
                            isClearable={true}
                            className="w-100"
                          />
                        </div>
                    )}
                      </Col>
                    </Row>

                    

                    <Row>
                      {tabDataLoader ? (
                        <Loader />
                      ) : (
                        <Col>
                        <h6 className="text-end fs-last-sync">Last Sync Time : <span className="fw-bold">{lastSyncTime?.last_sync_time}</span></h6>
                          <div className="position-relative">
                            <Tabs
                              id="controlled-tab-example"
                              activeKey={activeTab}
                              onSelect={(k) => {
                                console.log("kkkk",k)
                                sendMessageToReactNative(k);
                                // dispatch(setActiveTab(k));
                                handleSyncTimeData()
                              }}
                              className="mb-3"
                            >
                              <Tab eventKey="FTD" title="FTD">
                                <Container>
                                  {profitabitityboxes(
                                    ftdData?.metaData?.gross_profit,
                                    ftdData?.metaData?.total_discount,
                                    ftdData?.metaData?.expenses,
                                    ftdData?.metaData?.net_profit,
                                    ftdData?.metaData?.total_gst
                                  )}

                                  <Row>
                                    <Col>
                                      <Card className="p-4">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">PT</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={
                                                (ftdData?.metaData?.net_profit /
                                                  ftdData?.profit_data
                                                    ?.target_amount) *
                                                100
                                              }
                                              text={`${Math.trunc(
                                                (ftdData?.metaData?.net_profit /
                                                  ftdData?.profit_data
                                                    ?.target_amount) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Achieved</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              ftdData?.metaData?.net_profit
                                            )}
                                          </h5>
                                        </div>

                                        {ftdData?.mobile_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                ftdData?.profit_data
                                                  ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Gap</h5>
                                          <h5>
                                            {gapColor(
                                              ftdData?.metaData?.net_profit -
                                                ftdData?.profit_data
                                                  ?.target_amount
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card
                                        className="p-4 mt-2"
                                        onClick={() => {
                                          setQTModalData(
                                            ftdData?.mobile_data?.brands
                                          );
                                          setShowQTModal(true);
                                        }}
                                      >
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">QT</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={
                                                (ftdData?.mobile_data
                                                  ?.achieved_qty /
                                                  ftdData?.mobile_data
                                                    ?.target_vol) *
                                                100
                                              }
                                              text={`${Math.trunc(
                                                (ftdData?.mobile_data
                                                  ?.achieved_qty /
                                                  ftdData?.mobile_data
                                                    ?.target_vol) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>

                                        {ftdData?.mobile_data?.target_vol ==
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                ftdData?.mobile_data
                                                  ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}

                                        {ftdData?.mobile_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Achieved</h5>
                                            <h5>
                                              {
                                                ftdData?.mobile_data
                                                  ?.achieved_qty
                                              }
                                            </h5>
                                          </div>
                                        )}

                                        {ftdData?.mobile_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              {ftdData?.mobile_data?.target_vol}
                                            </h5>
                                          </div>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Gap</h5>
                                          <h5>
                                            {gapColor(
                                              ftdData?.mobile_data?.gap,
                                              "Mobiles"
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card
                                        className="p-4 mt-2 affort"
                                        onClick={() => {
                                          if(missedCustomerFtdDetails?.data?.missed_count){
                                              setMissedCustmerData({
                                                showModal: false,
                                                data: missedCustomerFtdDetails
                                                  ?.data?.missed_customer,
                                              });
                                              dispatch(setMissiedCustomerData(missedCustomerFtdDetails?.data?.missed_customer));
                                              navigate('/missedcustomer')
                                            }
                                          }}
                                      >
                                        <h6 className="m-0">
                                          Missed Customers
                                        </h6>
                                        <h5 className="mb-0">
                                          No.of Missed Customers:{" "}
                                          {
                                            missedCustomerFtdDetails?.data
                                              ?.missed_count
                                          }
                                        </h5>
                                        <h5 className="mb-0">
                                          Total Missed Amount:{" "}
                                          {
                                            missedCustomerFtdDetails?.data
                                              ?.missed_value
                                          }
                                        </h5>
                                      </Card>
                                      <Card className="p-4 mt-2 flag_card">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">Accessories</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={
                                                (ftdData?.acc_data?.achieved /
                                                  ftdData?.acc_data
                                                    ?.target_amount) *
                                                100
                                              }
                                              text={`${Math.trunc(
                                                (ftdData?.acc_data?.achieved /
                                                  ftdData?.acc_data
                                                    ?.target_amount) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Achieved</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              ftdData?.acc_data?.achieved
                                            )}
                                          </h5>
                                        </div>
                                        {ftdData?.acc_data?.target_vol == 0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                ftdData?.acc_data?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        {ftdData?.acc_data?.target_vol == 0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Gap</h5>
                                            <h5>
                                              {gapColor(
                                                ftdData?.acc_data?.achieved -
                                                  ftdData?.acc_data
                                                    ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>GP</h5>
                                          <h5>
                                            {gapColor(
                                              ftdData?.acc_data?.gp,
                                              "Accessories"
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Percentage</h5>
                                          <h5>
                                            {
                                              ftdData?.acc_data
                                                ?.contributed_percentage
                                            }
                                            %
                                          </h5>
                                        </div>

                                        {ftdData?.acc_data?.target_vol != 0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>QT</h5>
                                            <h5>
                                              {ftdData?.acc_data?.achieved_qty}/
                                              {ftdData?.acc_data?.target_vol}
                                            </h5>
                                          </div>
                                        )}
                                      </Card>
                                      <Card className="p-4 mt-2">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">Flashgard</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={Math.trunc(
                                                (ftdData?.flashgard_data
                                                  ?.achieved_qty /
                                                  ftdData?.flashgard_data
                                                    ?.target_vol) *
                                                  100
                                              )}
                                              text={`${Math.trunc(
                                                (ftdData?.flashgard_data
                                                  ?.achieved_qty /
                                                  ftdData?.flashgard_data
                                                    ?.target_vol) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>

                                        {ftdData?.flashgard_data?.target_vol ==
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                ftdData?.flashgard_data
                                                  ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}

                                        {ftdData?.flashgard_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Achieved</h5>
                                            <h5>
                                              {
                                                ftdData?.flashgard_data
                                                  ?.achieved_qty
                                              }
                                            </h5>
                                          </div>
                                        )}

                                        {ftdData?.flashgard_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              {
                                                ftdData?.flashgard_data
                                                  ?.target_vol
                                              }
                                            </h5>
                                          </div>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Gap</h5>
                                          <h5>
                                            {gapColor(
                                              ftdData?.flashgard_data?.gap,
                                              "Flashgard"
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card
                                        className="p-4 mt-2"
                                        onClick={() => {
                                          setQTModalData(
                                            ftdData?.mobile_data?.brands
                                          );
                                          setShowQTModal(true);
                                        }}
                                      >
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">Happi Care</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={Math.trunc(
                                                (ftdData?.happicare_data
                                                  ?.achieved /
                                                  ftdData?.happicare_data
                                                    ?.target_amount) *
                                                  100
                                              )}
                                              text={`${Math.trunc(
                                                (ftdData?.happicare_data
                                                  ?.achieved /
                                                  ftdData?.happicare_data
                                                    ?.target_amount) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Achieved</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              ftdData?.happicare_data?.achieved
                                            )}
                                          </h5>
                                        </div>
                                        {ftdData?.happicare_data?.target_vol ==
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                ftdData?.happicare_data
                                                  ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        {ftdData?.happicare_data?.target_vol ==
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Gap</h5>
                                            <h5>
                                              {gapColor(
                                                ftdData?.happicare_data
                                                  ?.achieved -
                                                  ftdData?.happicare_data
                                                    ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        {ftdData?.happicare_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>QT</h5>
                                            <h5>
                                              {
                                                ftdData?.happicare_data
                                                  ?.achieved_qty
                                              }
                                              /
                                              {
                                                ftdData?.happicare_data
                                                  ?.target_vol
                                              }
                                            </h5>
                                          </div>
                                        )}
                                      </Card>
                                      <Card className="p-4 mt-2 flag_card">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">
                                            Feature Phones
                                          </h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={Math.trunc(
                                                (ftdData?.fp_data
                                                  ?.achieved_qty /
                                                  ftdData?.fp_data
                                                    ?.target_vol) *
                                                  100
                                              )}
                                              text={`${Math.trunc(
                                                (ftdData?.fp_data
                                                  ?.achieved_qty /
                                                  ftdData?.fp_data
                                                    ?.target_vol) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Achieved</h5>
                                          <h5>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              ftdData?.fp_data?.achieved_qty
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Target</h5>
                                          <h5>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              ftdData?.fp_data?.target_vol
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Gap</h5>
                                          <h5>
                                            {gapColor(
                                              ftdData?.fp_data?.achieved_qty -
                                                ftdData?.fp_data?.target_vol,
                                              "featureMobiles"
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card className="p-4 mt-2">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">Spot Models</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Sale Qty</h5>
                                          <h5>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              ftdData?.sm_data?.sale_qty
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Sale Value</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              ftdData?.sm_data?.sale_value
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Cls Stock Qty</h5>
                                          <h5>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(ftdData?.sm_data?.cls_qty)}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Cls Stock Val</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              ftdData?.sm_data?.cls_value
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                    </Col>
                                    {affordabilityftdData?.loader ? (
                                      <Loader />
                                    ) : (
                                      <>
                                        <Card
                                          className="p-4 affort"
                                          onClick={() => {
                                            if (
                                              affordabilityftdData
                                                ?.affordability?.card_count &&
                                              affordabilityftdData
                                                ?.affordability?.data?.length >
                                                0
                                            ) {
                                              setshowAffortablePaymentModal(
                                                true
                                              );
                                              setAffortablePaymentModalData({
                                                data: [...affordabilityftdData
                                                  ?.affordability?.data],
                                                 others:affordabilityftdData?.others,
                                                isAffortableData: true,
                                                title: "Affordability",
                                                isPaymentData: false,
                                              });
                                            }
                                          }}
                                        >
                                          <h6 className="m-0">Affordability</h6>
                                          <h5 className="ms-3">
                                            Total:
                                            {
                                              affordabilityftdData
                                                ?.affordability?.card_count
                                            }
                                          </h5>
                                          <div></div>
                                        </Card>
                                        <Card
                                          className="p-4 mt-2"
                                          onClick={() => {
                                            if (
                                              affordabilityftdData?.payment
                                                ?.card_count &&
                                              affordabilityftdData?.payment
                                                ?.data?.length > 0
                                            ) {
                                              setshowAffortablePaymentModal(
                                                true
                                              );
                                              setAffortablePaymentModalData({
                                                data: affordabilityftdData
                                                  ?.payment?.data,
                                                isAffortableData: false,
                                                title: "Payment mode",
                                                isPaymentData: true,
                                              });
                                            }
                                          }}
                                        >
                                          <h6 className="m-0">Payment mode</h6>
                                          <h5 className="ms-3">
                                            Total:
                                            {
                                              affordabilityftdData?.payment
                                                ?.card_count
                                            }
                                          </h5>
                                        </Card>
                                      </>
                                    )}
                                  </Row>
                                </Container>
                              </Tab>
                              <Tab eventKey="MTD" title="MTD">
                                <Container>
                                  {profitabitityboxes(
                                    mtdData?.metaData?.gross_profit,
                                    mtdData?.metaData?.total_discount,
                                    mtdData?.metaData?.expenses,
                                    mtdData?.metaData?.net_profit,
                                    mtdData?.metaData?.total_gst
                                  )}
                                  <Row>
                                    <Col>
                                      <Card className="p-4 mt-2">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">PT</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={
                                                (mtdData?.metaData?.net_profit /
                                                  mtdData?.profit_data
                                                    ?.target_amount) *
                                                100
                                              }
                                              text={`${Math.trunc(
                                                (mtdData?.metaData?.net_profit /
                                                  mtdData?.profit_data
                                                    ?.target_amount) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Achieved</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              mtdData?.metaData?.net_profit
                                            )}
                                          </h5>
                                        </div>

                                        {mtdData?.mobile_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                mtdData?.profit_data
                                                  ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Gap</h5>
                                          <h5>
                                            {gapColor(
                                              mtdData?.metaData?.net_profit -
                                                mtdData?.profit_data
                                                  ?.target_amount
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card
                                        className="p-4 mt-2"
                                        onClick={() => {
                                          setQTModalData(
                                            mtdData?.mobile_data?.brands
                                          );
                                          setShowQTModal(true);
                                        }}
                                      >
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">QT</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={
                                                (mtdData?.mobile_data
                                                  ?.achieved_qty /
                                                  mtdData?.mobile_data
                                                    ?.target_vol) *
                                                100
                                              }
                                              text={`${Math.trunc(
                                                (mtdData?.mobile_data
                                                  ?.achieved_qty /
                                                  mtdData?.mobile_data
                                                    ?.target_vol) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>

                                        {mtdData?.mobile_data?.target_vol ==
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                mtdData?.mobile_data
                                                  ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}

                                        {mtdData?.mobile_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Achieved</h5>
                                            <h5>
                                              {
                                                mtdData?.mobile_data
                                                  ?.achieved_qty
                                              }
                                            </h5>
                                          </div>
                                        )}

                                        {mtdData?.mobile_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              {mtdData?.mobile_data?.target_vol}
                                            </h5>
                                          </div>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Gap</h5>
                                          <h5>
                                            {gapColor(
                                              mtdData?.mobile_data?.gap,
                                              "Mobiles"
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card
                                        className="p-4 mt-2 affort"
                                        onClick={() => {
                                          if(missedCustomerMtdDetails?.data?.missed_count){
                                              setMissedCustmerData({
                                                showModal: false,
                                                data: missedCustomerMtdDetails
                                                  ?.data?.missed_customer,
                                              });
                                              dispatch(setMissiedCustomerData( missedCustomerMtdDetails?.data?.missed_customer));
                                              navigate('/missedcustomer')
                                            }
                                         
                                           
                                        }}
                                      >
                                        <h6 className="m-0">
                                          Missed Customers
                                        </h6>
                                        <h5 className="mb-0">
                                          No.of Missed Customers:{" "}
                                          {
                                            missedCustomerMtdDetails?.data
                                              ?.missed_count
                                          }
                                        </h5>
                                        <h5 className="mb-0">
                                          Total Missed Amount:{" "}
                                          {
                                            missedCustomerMtdDetails?.data
                                              ?.missed_value
                                          }
                                        </h5>
                                      </Card>
                                      <Card className="p-4 mt-2 flag_card">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">Accessories</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={
                                                (mtdData?.acc_data?.achieved /
                                                  mtdData?.acc_data
                                                    ?.target_amount) *
                                                100
                                              }
                                              text={`${Math.trunc(
                                                (mtdData?.acc_data?.achieved /
                                                  mtdData?.acc_data
                                                    ?.target_amount) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "red",
                                              })}
                                            />
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Achieved</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              mtdData?.acc_data?.achieved
                                            )}
                                          </h5>
                                        </div>
                                        {mtdData?.acc_data?.target_vol == 0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                mtdData?.acc_data?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        {mtdData?.acc_data?.target_vol == 0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Gap</h5>
                                            <h5>
                                              {gapColor(
                                                mtdData?.acc_data?.achieved -
                                                  mtdData?.acc_data
                                                    ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        {mtdData?.acc_data?.target_vol != 0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>QT</h5>
                                            <h5>
                                              {mtdData?.acc_data?.achieved_qty}/
                                              {mtdData?.acc_data?.target_vol}
                                            </h5>
                                          </div>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>GP</h5>
                                          <h5>
                                            {gapColor(mtdData?.acc_data?.gp)}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Percentage</h5>
                                          <h5>
                                            {
                                              mtdData?.acc_data
                                                ?.contributed_percentage
                                            }
                                            %
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card className="p-4  mt-2">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">Flashgard</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={
                                                (mtdData?.flashgard_data
                                                  ?.achieved_qty /
                                                  mtdData?.flashgard_data
                                                    ?.target_vol) *
                                                100
                                              }
                                              text={`${Math.trunc(
                                                (mtdData?.flashgard_data
                                                  ?.achieved_qty /
                                                  mtdData?.flashgard_data
                                                    ?.target_vol) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>

                                        {mtdData?.flashgard_data?.target_vol ==
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                mtdData?.flashgard_data
                                                  ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}

                                        {mtdData?.flashgard_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Achieved</h5>
                                            <h5>
                                              {
                                                mtdData?.flashgard_data
                                                  ?.achieved_qty
                                              }
                                            </h5>
                                          </div>
                                        )}

                                        {mtdData?.flashgard_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              {
                                                mtdData?.flashgard_data
                                                  ?.target_vol
                                              }
                                            </h5>
                                          </div>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Gap</h5>
                                          <h5>
                                            {gapColor(
                                              mtdData?.flashgard_data?.gap,
                                              "Flashgard"
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card className="p-4 mt-2">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">Happi Care</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={
                                                (mtdData?.happicare_data
                                                  ?.achieved /
                                                  mtdData?.happicare_data
                                                    ?.target_amount) *
                                                100
                                              }
                                              text={`${Math.trunc(
                                                (mtdData?.happicare_data
                                                  ?.achieved /
                                                  mtdData?.happicare_data
                                                    ?.target_amount) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Achieved</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              mtdData?.happicare_data?.achieved
                                            )}
                                          </h5>
                                        </div>
                                        {mtdData?.happicare_data?.target_vol ==
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                mtdData?.happicare_data
                                                  ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        {mtdData?.happicare_data?.target_vol ==
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Gap</h5>
                                            <h5>
                                              {gapColor(
                                                mtdData?.happicare_data
                                                  ?.achieved -
                                                  mtdData?.happicare_data
                                                    ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        {mtdData?.happicare_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>QT</h5>
                                            <h5>
                                              {
                                                mtdData?.happicare_data
                                                  ?.achieved_qty
                                              }
                                              /
                                              {
                                                mtdData?.happicare_data
                                                  ?.target_vol
                                              }
                                            </h5>
                                          </div>
                                        )}
                                      </Card>
                                      <Card className="p-4 mt-2 flag_card">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">
                                            Feature Phones
                                          </h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={Math.trunc(
                                                (mtdData?.fp_data
                                                  ?.achieved_qty /
                                                  mtdData?.fp_data
                                                    ?.target_vol) *
                                                  100
                                              )}
                                              text={`${Math.trunc(
                                                (mtdData?.fp_data
                                                  ?.achieved_qty /
                                                  mtdData?.fp_data
                                                    ?.target_vol) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Achieved</h5>
                                          <h5>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              mtdData?.fp_data?.achieved_qty
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Target</h5>
                                          <h5>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              mtdData?.fp_data?.target_vol
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Gap</h5>
                                          <h5>
                                            {gapColor(
                                              mtdData?.fp_data?.achieved_qty -
                                                mtdData?.fp_data?.target_vol,
                                              "featureMobiles"
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card className="p-4 mt-2">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">Spot Models</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Sale Qty</h5>
                                          <h5>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              mtdData?.sm_data?.sale_qty
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Sale Value</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              mtdData?.sm_data?.sale_value
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Cls Stock Qty</h5>
                                          <h5>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(mtdData?.sm_data?.cls_qty)}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Cls Stock Val</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              mtdData?.sm_data?.cls_value
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card className="p-4 mt-2">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">Spot Models</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Sale Qty</h5>
                                          <h5>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              mtdData?.sm_data?.sale_qty
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Sale Value</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              mtdData?.sm_data?.sale_value
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Cls Stock Qty</h5>
                                          <h5>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(mtdData?.sm_data?.cls_qty)}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Cls Stock Val</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              mtdData?.sm_data?.cls_value
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                    </Col>
                                    {affordabilitymtdData?.loader ? (
                                      <Loader />
                                    ) : (
                                      <>
                                        <Card
                                          className="p-4 mt-2 affort"
                                          onClick={() => {
                                            if (
                                              affordabilitymtdData
                                                ?.affordability?.card_count &&
                                              affordabilitymtdData
                                                ?.affordability?.data?.length >
                                                0
                                            ) {
                                              setshowAffortablePaymentModal(
                                                true
                                              );
                                              setAffortablePaymentModalData({
                                                data: [...affordabilitymtdData
                                                  ?.affordability?.data],
                                                  others:affordabilitymtdData?.others,
                                                isAffortableData: true,
                                                title: "Affordability",
                                                isPaymentData: false,
                                              });
                                            }
                                          }}
                                        >
                                          <h6 className="m-0">Affordability</h6>
                                          <h5 className="ms-3">
                                            Total:
                                            {
                                              affordabilitymtdData
                                                ?.affordability?.card_count
                                            }
                                          </h5>
                                          <div></div>
                                        </Card>
                                        <Card
                                          className="p-4 mt-2"
                                          onClick={() => {
                                            if (
                                              affordabilitymtdData?.payment
                                                ?.card_count &&
                                              affordabilitymtdData?.payment
                                                ?.data?.length > 0
                                            ) {
                                              setshowAffortablePaymentModal(
                                                true
                                              );
                                              setAffortablePaymentModalData({
                                                data:affordabilitymtdData
                                                  ?.payment?.data,
                                                isAffortableData: false,
                                                title: "Payment mode",
                                                isPaymentData: true,
                                              });
                                            }
                                          }}
                                        >
                                          <h6 className="m-0">Payment mode</h6>
                                          <h5 className="ms-3">
                                            Total:
                                            {
                                              affordabilitymtdData?.payment
                                                ?.card_count
                                            }
                                          </h5>
                                        </Card>
                                      </>
                                    )}
                                  </Row>
                                </Container>
                              </Tab>

                              <Tab eventKey="CUSTOM" title="CUSTOM">
                                <Container>
                                  <Row>
                                    <Col>
                                      <div className="d-flex align-items-center w-100 gap-2 selectdate mb-2">
                                        <label>Select Dates :</label>
                                        <div className="position-relative z-1">
                                          <DatePicker
                                            selected={customStartDate}
                                            onChange={handleChange}
                                            selectsRange
                                            isClearable
                                            startDate={customStartDate}
                                            endDate={customEndDate}
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date(y, 5)}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  {profitabitityboxes(
                                    monthData?.metaData?.gross_profit,
                                    monthData?.metaData?.total_discount,
                                    monthData?.metaData?.expenses,
                                    monthData?.metaData?.net_profit,
                                    monthData?.metaData?.total_gst
                                  )}
                                  <Row>
                                    <Col>
                                      <Card className="p-4">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">PT</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={
                                                (monthData?.metaData
                                                  ?.net_profit /
                                                  monthData?.profit_data
                                                    ?.target_amount) *
                                                100
                                              }
                                              text={`${Math.trunc(
                                                (monthData?.metaData
                                                  ?.net_profit /
                                                  monthData?.profit_data
                                                    ?.target_amount) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Achieved</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              monthData?.metaData?.net_profit
                                            )}
                                          </h5>
                                        </div>

                                        {monthData?.mobile_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                monthData?.profit_data
                                                  ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Gap</h5>
                                          <h5>
                                            {gapColor(
                                              monthData?.metaData?.net_profit -
                                                monthData?.profit_data
                                                  ?.target_amount
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card
                                        className="p-4 mt-2"
                                        onClick={() => {
                                          setQTModalData(
                                            monthData?.mobile_data?.brands
                                          );
                                          setShowQTModal(true);
                                        }}
                                      >
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">QT</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={
                                                (monthData?.mobile_data
                                                  ?.achieved_qty /
                                                  monthData?.mobile_data
                                                    ?.target_vol) *
                                                100
                                              }
                                              text={`${Math.trunc(
                                                (monthData?.mobile_data
                                                  ?.achieved_qty /
                                                  monthData?.mobile_data
                                                    ?.target_vol) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>

                                        {monthData?.mobile_data?.target_vol ==
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                monthData?.mobile_data
                                                  ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}

                                        {monthData?.mobile_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Achieved</h5>
                                            <h5>
                                              {
                                                monthData?.mobile_data
                                                  ?.achieved_qty
                                              }
                                            </h5>
                                          </div>
                                        )}

                                        {monthData?.mobile_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              {
                                                monthData?.mobile_data
                                                  ?.target_vol
                                              }
                                            </h5>
                                          </div>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Gap</h5>
                                          <h5>
                                            {gapColor(
                                              monthData?.mobile_data?.gap,
                                              "Mobiles"
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card
                                        className="p-4  mt-2 affort"
                                        onClick={() => {
                                          if(missedCustomerMonthDetails?.data
                                            ?.missed_count){
                                              setMissedCustmerData({
                                                showModal: false,
                                                data: missedCustomerMonthDetails
                                                  ?.data?.missed_customer,
                                              });
                                              dispatch(setMissiedCustomerData(missedCustomerMonthDetails?.data?.missed_customer));
                                              navigate('/missedcustomer')
                                          }
                                        }}
                                      >
                                        <h6 className="m-0">
                                          Missed Customers
                                        </h6>
                                        <h5 className="mb-0">
                                          No.of Missed Customers:{" "}
                                          {
                                            missedCustomerMonthDetails?.data
                                              ?.missed_count
                                          }
                                        </h5>
                                        <h5 className="mb-0">
                                          Total Missed Amount:{" "}
                                          {
                                            missedCustomerMonthDetails?.data
                                              ?.missed_value
                                          }
                                        </h5>
                                      </Card>
                                      <Card className="p-4 mt-2 flag_card">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">Accessories</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={
                                                (monthData?.acc_data?.achieved /
                                                  monthData?.acc_data
                                                    ?.target_amount) *
                                                100
                                              }
                                              text={`${Math.trunc(
                                                (monthData?.acc_data?.achieved /
                                                  monthData?.acc_data
                                                    ?.target_amount) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Achieved</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              monthData?.acc_data?.achieved
                                            )}
                                          </h5>
                                        </div>
                                        {monthData?.acc_data?.target_vol ==
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                monthData?.acc_data
                                                  ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        {monthData?.acc_data?.target_vol ==
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Gap</h5>
                                            <h5>
                                              {gapColor(
                                                monthData?.acc_data?.achieved -
                                                  monthData?.acc_data
                                                    ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        {monthData?.acc_data?.target_vol !=
                                          0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>QT</h5>
                                            <h5>
                                              {
                                                monthData?.acc_data
                                                  ?.achieved_qty
                                              }
                                              /{monthData?.acc_data?.target_vol}
                                            </h5>
                                          </div>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>GP</h5>
                                          <h5>
                                            {gapColor(monthData?.acc_data?.gp)}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Percentage</h5>
                                          <h5>
                                            {
                                              monthData?.acc_data
                                                ?.contributed_percentage
                                            }
                                            %
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card className="p-4 mt-2">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">Flashgard</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={Math.trunc(
                                                (monthData?.flashgard_data
                                                  ?.achieved_qty /
                                                  monthData?.flashgard_data
                                                    ?.target_vol) *
                                                  100
                                              )}
                                              text={`${Math.trunc(
                                                (monthData?.flashgard_data
                                                  ?.achieved_qty /
                                                  monthData?.flashgard_data
                                                    ?.target_vol) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>

                                        {monthData?.flashgard_data
                                          ?.target_vol == 0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                monthData?.flashgard_data
                                                  ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}

                                        {monthData?.flashgard_data
                                          ?.target_vol != 0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Achieved</h5>
                                            <h5>
                                              {
                                                monthData?.flashgard_data
                                                  ?.achieved_qty
                                              }
                                            </h5>
                                          </div>
                                        )}

                                        {monthData?.flashgard_data
                                          ?.target_vol != 0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              {
                                                monthData?.flashgard_data
                                                  ?.target_vol
                                              }
                                            </h5>
                                          </div>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Gap</h5>
                                          <h5>
                                            {gapColor(
                                              monthData?.flashgard_data?.gap,
                                              "Flashgard"
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card className="p-4 mt-2">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">Happi Care</h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={Math.trunc(
                                                (monthData?.happicare_data
                                                  ?.achieved /
                                                  monthData?.happicare_data
                                                    ?.target_amount) *
                                                  100
                                              )}
                                              text={`${Math.trunc(
                                                (monthData?.happicare_data
                                                  ?.achieved /
                                                  monthData?.happicare_data
                                                    ?.target_amount) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Achieved</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              monthData?.happicare_data
                                                ?.achieved
                                            )}
                                          </h5>
                                        </div>
                                        {monthData?.happicare_data
                                          ?.target_vol == 0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Target</h5>
                                            <h5>
                                              &#8377;
                                              {new Intl.NumberFormat(
                                                "en-IN"
                                              ).format(
                                                monthData?.happicare_data
                                                  ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        {monthData?.happicare_data
                                          ?.target_vol == 0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Gap</h5>
                                            <h5>
                                              {gapColor(
                                                monthData?.happicare_data
                                                  ?.achieved -
                                                  monthData?.happicare_data
                                                    ?.target_amount
                                              )}
                                            </h5>
                                          </div>
                                        )}
                                        {monthData?.happicare_data
                                          ?.target_vol != 0 && (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h5>Vol</h5>
                                            <h5>
                                              {
                                                monthData?.happicare_data
                                                  ?.achieved_qty
                                              }
                                              /
                                              {
                                                monthData?.happicare_data
                                                  ?.target_vol
                                              }
                                            </h5>
                                          </div>
                                        )}
                                      </Card>
                                      <Card className="p-4 mt-2 flag_card">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">
                                            Feature Phones
                                          </h6>
                                          <div className="circular-progress-bar">
                                            <CircularProgressbar
                                              value={Math.trunc(
                                                (monthData?.fp_data
                                                  ?.achieved_qty /
                                                  monthData?.fp_data
                                                    ?.target_vol) *
                                                  100
                                              )}
                                              text={`${Math.trunc(
                                                (monthData?.fp_data
                                                  ?.achieved_qty /
                                                  monthData?.fp_data
                                                    ?.target_vol) *
                                                  100
                                              )}%`}
                                              strokeWidth={11}
                                              styles={buildStyles({
                                                rotation: 0.25,
                                                strokeLinecap: "butt",
                                                textSize: "16px",
                                                pathTransitionDuration: 0.5,
                                                pathColor: `rgba(250, 144, 18, ${
                                                  percentage / 100
                                                })`,
                                                textColor: "#fb9013",
                                                trailColor: "#d6d6d6",
                                                backgroundColor: "#3e98c7",
                                              })}
                                            />
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Achieved</h5>
                                          <h5>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              monthData?.fp_data?.achieved_qty
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Target</h5>
                                          <h5>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              monthData?.fp_data?.target_vol
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Gap</h5>
                                          <h5>
                                            {gapColor(
                                              monthData?.fp_data?.achieved_qty -
                                                monthData?.fp_data?.target_vol,
                                              "featureMobiles"
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                      <Card className="p-4 mt-2">
                                        <div className="d-flex align-items-center mb-3">
                                          <h6 className="m-0">Spot Models</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Sale Qty</h5>
                                          <h5>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              monthData?.sm_data?.sale_qty
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Sale Value</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              monthData?.sm_data?.sale_value
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Cls Stock Qty</h5>
                                          <h5>
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              monthData?.sm_data?.cls_qty
                                            )}
                                          </h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>Cls Stock val</h5>
                                          <h5>
                                            &#8377;
                                            {new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              monthData?.sm_data?.cls_value
                                            )}
                                          </h5>
                                        </div>
                                      </Card>
                                    </Col>
                                    {affordabilityMonthData?.loader ? (
                                      <Loader />
                                    ) : (
                                      <>
                                        <Card
                                          className="p-4 mt-2 affort"
                                          onClick={() => {
                                            if (
                                              affordabilityMonthData
                                                ?.affordability?.card_count &&
                                              affordabilityMonthData
                                                ?.affordability?.data?.length >
                                                0
                                            ) {
                                              setshowAffortablePaymentModal(
                                                true
                                              );
                                              setAffortablePaymentModalData({
                                                data: [...affordabilityMonthData
                                                  ?.affordability?.data],
                                                others:affordabilityMonthData.others,
                                                isAffortableData: true,
                                                title: "Affordability",
                                                isPaymentData: false,
                                              });
                                            }
                                          }}
                                        >
                                          <h6 className="m-0">Affordability</h6>
                                          <h5 className="ms-3">
                                            Total:
                                            {
                                              affordabilityMonthData
                                                ?.affordability?.card_count
                                            }
                                          </h5>
                                          <div></div>
                                        </Card>
                                        <Card
                                          className="p-4 mt-2"
                                          onClick={() => {
                                            if (
                                              affordabilityMonthData?.payment
                                                ?.card_count &&
                                              affordabilityMonthData?.payment
                                                ?.data?.length > 0
                                            ) {
                                              setshowAffortablePaymentModal(
                                                true
                                              );
                                              setAffortablePaymentModalData({
                                                data: affordabilityMonthData
                                                  ?.payment?.data,
                                                isAffortableData: false,
                                                title: "Payment mode",
                                                isPaymentData: true,
                                              });
                                            }
                                          }}
                                        >
                                          <h6 className="m-0">Payment mode</h6>
                                          <h5 className="ms-3">
                                            Total:
                                            {
                                              affordabilityMonthData?.payment
                                                ?.card_count
                                            }
                                          </h5>
                                        </Card>
                                      </>
                                    )}
                                  </Row>
                                </Container>
                              </Tab>

                              <Tab
                                eventKey="ProfitSheets"
                                title="PROFIT PLUS+"
                                className="position-relative"
                              >
                                <Container>
                                  <Row>
                                    <Col lg={12}>
                                      <div className="position-relative my-2">
                                        <CiSearch className="position-absolute search" />
                                        <input
                                          type="text"
                                          placeholder="Search with Name"
                                          className="w-100 ps-4"
                                          value={profitSheetSearch}
                                          onChange={(e) =>
                                            handleProfitsheetsGlobalSearch(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={12} className="mt-2">
                                      {globalSearchResults?.length > 0 && (
                                        <Table
                                          bordered
                                          hover
                                          className="profitsheets-table"
                                        >
                                          <thead>
                                            <tr>
                                              <th>#</th>
                                              <th>ITEM</th>
                                              <th>MOP</th>
                                              <th>GP</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {globalSearchResults?.map(
                                              (eachitem, index) => {
                                                return (
                                                  <tr
                                                    onClick={() =>
                                                      discountCalculation(
                                                        eachitem
                                                      )
                                                    }
                                                  >
                                                    <td
                                                      style={{
                                                        color:
                                                          "rgba(250, 144, 18, 0.66)",
                                                      }}
                                                    >
                                                      {<FiPlusCircle />}
                                                    </td>
                                                    <td
                                                      style={{ width: "150px" }}
                                                    >
                                                      {eachitem?.item}
                                                    </td>
                                                    <td>
                                                      &#8377;{eachitem?.inr_mop}
                                                    </td>
                                                    <td>
                                                      &#8377;{eachitem?.inr_gp}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </Table>
                                      )}
                                    </Col>
                                  </Row>
                                </Container>
                              </Tab>

                              <Tab eventKey="Transations" title="TRANSACTIONS">
                                <div className="flag_transaction_card">
                                  <Col>
                                    <div className="d-flex align-items-center w-100 gap-2 selectdate mb-2">
                                      <label>Select Dates :</label>
                                      <DatePicker
                                        selected={transactionStartDate}
                                        onChange={handletransChange}
                                        selectsRange
                                        isClearable
                                        startDate={transactionStartDate}
                                        endDate={transactionEndDate}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date(y, 4)}
                                      />
                                    </div>
                                    <div className="d-flex align-items-center w-100 gap-2 selectdate my-2 orange">
                                      <label className="d-flex flex-column orange">
                                        Total G :{" "}
                                        <span>(Without Expenses)</span>
                                      </label>
                                      <h6>
                                        {" "}
                                        &#8377;
                                        {transactionsData?.profitDetails !=
                                        undefined
                                          ? new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              transactionsData?.profitDetails
                                                ?.totalGross
                                            )
                                          : 0}
                                      </h6>
                                    </div>
                                    <div className="d-flex align-items-center w-100 gap-2 selectdate my-2 white">
                                      <label className="d-flex flex-column label-w-ctrl">
                                        Total D :
                                      </label>
                                      <h6 className="m-0">
                                        {" "}
                                        &#8377;
                                        {transactionsData?.profitDetails !=
                                        undefined
                                          ? new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              transactionsData?.profitDetails
                                                ?.totalDiscount
                                            )
                                          : 0}
                                      </h6>
                                    </div>
                                    <div className="d-flex align-items-center w-100 gap-2 selectdate my-2 green">
                                      <label className="d-flex flex-column label-w-ctrl">
                                        Total T :
                                      </label>
                                      <h6 className="m-0">
                                        {" "}
                                        &#8377;
                                        {transactionsData?.profitDetails !=
                                        undefined
                                          ? new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              transactionsData?.profitDetails
                                                ?.totalGst
                                            )
                                          : 0}
                                      </h6>
                                    </div>
                                    <div className="d-flex align-items-center w-100 gap-2 selectdate my-2">
                                      <label className="d-flex flex-column label-w-ctrl">
                                        Total P :
                                      </label>
                                      <h6 className="m-0">
                                        {" "}
                                        &#8377;
                                        {transactionsData?.netProfit !=
                                        undefined
                                          ? new Intl.NumberFormat(
                                              "en-IN"
                                            ).format(
                                              transactionsData?.netProfit
                                            )
                                          : 0}
                                      </h6>
                                    </div>
                                  </Col>
                                </div>
                                {transactionsData?.transactions?.length > 0 && (
                                  <div className="flag_table">
                                    <DataGrid
                                      columns={columns1}
                                      rows={transactionsData?.transactions}
                                    />
                                  </div>
                                )}
                                {/* <p style = {{textAlign:"center"}}>Under Maintenance</p> */}
                              </Tab>
                              {approver == "store" && (
                                <Tab
                                  eventKey="Missed Customer"
                                  title="MISSED CUSTOMER"
                                >
                                  <Row>
                                    <Col>
                                      <div className="position-relative my-2">
                                        <CiSearch className="position-absolute search" />
                                        <input
                                          type="text"
                                          placeholder="Search with Name"
                                          className="w-100 ps-4"
                                          value={profitSheetSearch}
                                          onChange={(e) =>
                                            handleProfitsheetsGlobalSearch(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col className="mt-2">
                                      <Table
                                        striped
                                        bordered
                                        hover
                                        className="profitsheets-table"
                                      >
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>ITEM</th>
                                            <th>MOP</th>
                                            <th>GP</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {globalSearchResults?.length > 0 &&
                                            globalSearchResults?.map(
                                              (eachitem, index) => {
                                                return (
                                                  <tr
                                                    onClick={() =>
                                                      openCustomerDetailsPage(
                                                        eachitem
                                                      )
                                                    }
                                                  >
                                                    <td
                                                      style={{
                                                        color:
                                                          "rgba(250, 144, 18, 0.66)",
                                                      }}
                                                    >
                                                      {<FiPlusCircle />}
                                                    </td>
                                                    <td
                                                      style={{ width: "150px" }}
                                                    >
                                                      {eachitem?.item}
                                                    </td>
                                                    <td>
                                                      &#8377;{eachitem?.inr_mop}
                                                    </td>
                                                    <td>
                                                      &#8377;{eachitem?.inr_gp}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>
                                </Tab>
                              )}
                            </Tabs>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {loader ? (
                        <Loader />
                      ) : (
                        <>
                          {allTransferList?.length > 0 ? (
                            <>
                              {allTransferList?.map((stock, id) => {
                                return (
                                  <Col sm={3}>
                                    <Card className="mt-3" key={id}>
                                      <ListGroup variant="flush">
                                        <ListGroup.Item>
                                          <label>Brand:</label>
                                          <p>{stock?.brand}</p>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                          <label>Model:</label>
                                          <p>{stock?.model}</p>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                          <label>Status:</label>
                                          <p>{stock?.status}</p>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                          <label>From StoreName:</label>
                                          <p>{stock?.from_store_name}</p>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                          <label>To StoreName:</label>
                                          <p>{stock?.to_store_name}</p>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                          <label>Quantity:</label>
                                          <p>{stock?.quantity}</p>
                                        </ListGroup.Item>
                                      </ListGroup>
                                    </Card>
                                  </Col>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Row>
                  </section>
                </Col>
              </Row>
            </Container>

            <Modal
              show={show}
              onHide={handleClose}
              size="xl"
              scrollable={true}
              className="profitability_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>Profit Plus+</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Watermark text={`-${empId}-`}>
                  <div className="d-flex mb-2 ps-3">
                    <h5 className="text-center ww-ctrl ps-5 m-0 acc-header">
                      {" "}
                      ITEM
                    </h5>
                    <h5 className="w-ctrl-2 text-center ps-3 pe-0  m-0 acc-header">
                      MOP
                    </h5>
                    <h5 className="text-center m-0 w-ctrl-3 pe-5 acc-header">
                      GP
                    </h5>
                  </div>
                  {selectedDevice?.length > 0 &&
                    selectedDevice?.map((eachitem, index) => {
                      return (
                        <>
                          {" "}
                          <div className="w-100 py-2 border-bottom border-secondary-subtle">
                            <div className="d-flex w-100">
                              <h6 className="m-0 w-ctrl d-flex gap-2">
                                <input
                                  type="checkbox"
                                  value={eachitem}
                                  checked={check(eachitem)}
                                  onChange={handleCheckboxChange}
                                />{" "}
                                {eachitem.item}
                              </h6>{" "}
                              <h6 className="m-0 px-1 w-ctrl-2">
                                &#8377;{eachitem.inr_mop}
                              </h6>{" "}
                              <h6 className="m-0 px-1 w-ctrl-3">
                                &#8377;{eachitem.inr_gp}
                              </h6>
                            </div>
                          </div>
                        </>
                      );
                    })}

                  <div className="position-relative my-2">
                    <CiSearch className="position-absolute search" />
                    <input
                      type="text"
                      placeholder="search with Name"
                      className="w-100 ps-4"
                      value={searchTerm}
                      onChange={(e) => handleSearchChange(e.target.value)}
                    />
                    <RxCrossCircled
                      className="position-absolute clear"
                      onClick={clearSearch}
                    />
                  </div>
                  {searchResults?.map((eachdevice, index) => (
                    <div className="w-100 py-2 border-bottom border-secondary-subtle">
                      <div className="d-flex w-100">
                        <h6 className="m-0 w-ctrl d-flex gap-2">
                          {/* <input
                                            type="checkbox"
                                            // checked={check(eachdevice)}
                                            value={eachdevice}
                                            onChange={handleCheckboxChange}
                                          />{" "} */}

                          {/* {selectedDevice?.length>0 && selectedDevice?.some((device) => device.item == eachdevice.item) ?  ( <div>  <FiMinusCircle /> </div>):  (<div> <FiPlusCircle onClick = {() => addItem(eachdevice)}/> </div>)} */}
                          {checkicon(eachdevice)}

                          {eachdevice.item}
                        </h6>{" "}
                        <h6 className="m-0 px-1 w-ctrl-2">
                          &#8377;{eachdevice.inr_mop}
                        </h6>{" "}
                        <h6 className="m-0 px-1 w-ctrl-3">
                          &#8377;{eachdevice.inr_gp}
                        </h6>
                      </div>
                    </div>
                  ))}

                  <div className="p-2  d-flex align-items-center justify-content-between">
                    <h6>D</h6>
                    <input
                      type="number"
                      placeholder="Enter Discount"
                      value={discountValue}
                      className="ml-auto"
                      onChange={(e) => {
                        setDiscountValue(
                          e.target.value == "" ? 0 : parseInt(e.target.value)
                        );
                      }}
                      style={{
                        maxWidth: "80px",
                        // marginRight: "48px",
                        textAlign: "right",
                      }}
                    />
                  </div>
                  {/* <p style = {{fontSize:"10px",marginLeft:"150px"}}>Maximum Discount : &#8377;{maxDiscountValue}</p> */}
                  {/* <div className="d-flex p-2 align-items-center justify-content-between mop">
                <h6 className="m-0">
                  Max Discount : 
                </h6>
                <h6 className="m-0 pe-5">&#8377;{maxDiscountValue}</h6>
              </div> */}
                  <div className="d-flex p-2 align-items-center justify-content-between mop">
                    <h6 className="m-0">
                      Total MOP<span>(with Discount)</span>
                    </h6>
                    <h6 className="m-0 pe-5">&#8377;{totalmop}</h6>
                  </div>
                  <div className="d-flex p-2 align-items-center justify-content-between">
                    <h6 className="m-0">Margin</h6>
                    <h6 className="m-0  pe-5">&#8377;{profit}</h6>
                  </div>
                  <div className="d-flex p-2 align-items-center justify-content-between">
                    <h6 className="m-0">T</h6>
                    <h6 className="m-0 pe-5">
                      &#8377;
                      {Math.trunc((profit / 1.18) * 0.18 * 0.9).toFixed(0)}
                    </h6>
                  </div>
                  <div className="d-flex p-2 align-items-center justify-content-between mop">
                    <h6 className="m-0">
                      P/L <span>(Without Expenses)</span>
                    </h6>
                    <h6 className="m-0 text-white-pf pe-5">
                      {gapColor(
                        profit -
                          Math.trunc((profit / 1.18) * 0.18 * 0.9).toFixed(0)
                      )}
                    </h6>
                  </div>
                </Watermark>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={customerDetailsModel}
              onHide={closeCustomerDetailsModel}
              size="xl"
              scrollable={true}
              className="profitability_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>Customer Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Watermark text={`-${empId}-`}>
                  <div className="d-flex align-items-center">
                    <label
                      className="ps-2"
                      style={{ "min-width": "150px", "font-weight": "500" }}
                    >
                      Item Name :
                    </label>
                    <span>{customerSelectedItem?.item}</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <label
                      className="ps-2"
                      style={{ "min-width": "150px", "font-weight": "500" }}
                    >
                      MOP :
                    </label>
                    <span>{customerSelectedItem?.mop}</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <label
                      className="ps-2"
                      style={{ "min-width": "150px", "font-weight": "500" }}
                    >
                      GP :
                    </label>
                    <span>{customerSelectedItem?.gp.toFixed(2)}</span>
                  </div>
                  <div className="p-2  d-flex align-items-center justify-content-between">
                    <h6 style={{ minWidth: "150px" }}>QTY : </h6>
                    <input
                      type="number"
                      placeholder="Enter QTY"
                      value={discountValue}
                      className="ml-auto"
                      onChange={(e) => {
                        setCustomerDetails({
                          ...customerDetails,
                          qty: e.target.value,
                        });
                      }}
                      style={{ marginRight: "48px" }}
                    />
                  </div>
                  <div className="p-2  d-flex align-items-center justify-content-between">
                    <h6 style={{ minWidth: "150px" }}>Customer Name : </h6>
                    <input
                      type="text"
                      placeholder="Enter Customer Name"
                      value={discountValue}
                      onChange={(e) => {
                        setCustomerDetails({
                          ...customerDetails,
                          name: e.target.value,
                        });
                      }}
                      style={{ marginRight: "48px" }}
                    />
                  </div>
                  <div className="p-2  d-flex align-items-center justify-content-between">
                    <h6 style={{ minWidth: "150px" }}>Customer Number : </h6>
                    <input
                      type="number"
                      placeholder="Enter Customer Number"
                      value={discountValue}
                      onChange={(e) => {
                        setCustomerDetails({
                          ...customerDetails,
                          number: e.target.value,
                        });
                      }}
                      style={{ marginRight: "48px" }}
                    />
                  </div>
                  <Button
                    className="submitButton"
                    onClick={submitCustomerDetails}
                  >
                    Submit
                  </Button>
                </Watermark>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeCustomerDetailsModel}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Watermark>
        </>
      ) : (
        <p
          style={{ textAlign: "center", marginTop: "200px", fontSize: "2rem" }}
        >
          we are under maintenance and you can use the application from tomorrow
          11:00am
        </p>
      )}
      <Modal
        show={lossGainModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-modal"
        scrollable={true}
        onHide={() => setLossGainModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalTitle.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {modalTitle?.data?.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setLossGainModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showQTModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-modal"
        scrollable={true}
        onHide={() => setShowQTModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {"Brands"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Qty</th>
              </tr>
            </thead>
            <tbody>
              {qtModalData?.map((item) => (
                <tr>
                  <td> {item.name}</td>
                  <td> {item.qty}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowQTModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showAffortablePaymentModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-modal"
        scrollable={true}
        onHide={() => setshowAffortablePaymentModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`${affortablePaymentModalData.title}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {affortablePaymentModalData?.isAffortableData && (
            <table className="table">
              <thead>
                <tr>
                  <th>NBFC Name</th>
                  <th>Files Count</th>
                  <th>% Share</th>
                </tr>
              </thead>
              <tbody>
                {affortablePaymentModalData?.data?.map((item) => (
                  <tr>
                    <td>{item.card}</td>
                    <td>{item.card_count}</td>
                    <td>% {item.percentage_share}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {affortablePaymentModalData?.isAffortableData &&<div>
          <h6>Others</h6>
          <p>Others : {affortablePaymentModalData?.others?.card_count}</p>
          </div>}

          {affortablePaymentModalData?.isPaymentData && (
            <table className="table">
              <thead>
                <tr>
                  <th>Payment Type</th>
                  <th>Files Count</th>
                  <th>% Share</th>
                </tr>
              </thead>
              <tbody>
                {affortablePaymentModalData?.data?.map((item) => (
                  <tr>
                    <td>{item.card_type}</td>
                    <td>{item.card_count}</td>
                    <td>% {item.percentage_share}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setshowAffortablePaymentModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={missedCustomerData?.showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-modal"
        scrollable={true}
        onHide={() => setMissedCustmerData({ showModal: false, data: [] })}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`Missed Customers`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <thead>
              <tr>
                <th>Brand Name</th>
                <th>Item Name</th>
                <th>Quantity</th>
                <th>MOP</th>
              </tr>
            </thead>
            <tbody>
              {missedCustomerData?.data?.map((item) => (
                <tr>
                  <td>{item.brand_name}</td>
                  <td>{item.item_name}</td>
                  <td>{item.qty}</td>
                  <td>{item.mop}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setshowAffortablePaymentModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
