import axios from "axios";
import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import {Row, Col, Pagination } from "react-bootstrap";
import "./queuejobs.scss";
import happibg from '../../assets/happibg.jpg'







import moment from "moment";

//Re-usable compoenent that receives data through props and displays it.

const QueueJobs = () => {
    const [data,setData]=useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    useEffect(()=>{
        getQueueJobsData()
    },[currentPage])

    const getQueueJobsData=async()=>{
        try{
            let {data}=await axios.get(`https://tm-api.happimobiles.com/api/queueJobs?pageSize=20&pageNumber=${currentPage}`);
            console.log(data,"data")
            setData(data.data)
            setTotalPages(data?.metaData.pages)
        }
        catch(e){
            console.log(e.message)
        }
    }

    const getPaginationItems = () => {
        const items = [];
        const maxPagesToShow = 6; // Maximum number of page buttons to display
        const leftDotsThreshold = 3; // Pages before current to show dots
        const rightDotsThreshold = 3; // Pages after current to show dots
    
        // If there are less than or equal to max pages, show all
        if (totalPages <= maxPagesToShow) {
          for (let i = 1; i <= totalPages; i++) {
            items.push(i);
          }
        } else {
          // Always show the first page
          items.push(1);
          
          // Add left dots if necessary
          if (currentPage > leftDotsThreshold + 1) {
            items.push('...');
          }
    
          // Determine which pages to show around the current page
          const startPage = Math.max(2, currentPage - leftDotsThreshold);
          const endPage = Math.min(totalPages - 1, currentPage + rightDotsThreshold);
    
          for (let i = startPage; i <= endPage; i++) {
            items.push(i);
          }
    
          // Add right dots if necessary
          if (currentPage < totalPages - rightDotsThreshold) {
            items.push('...');
          }
    
          // Always show the last page
          items.push(totalPages);
        }
    
        return items;
      };
  
 
  return (

    <>
       <div className='previous-page d-flex containerbg align-items-center justify-content-between'>
        <div className='d-flex gap-2 align-items-center justify-content-between'>
        <img src={happibg} height={50} width={120}/>
        <div className='d-flex w-100 align-items-center mt-2'>
        </div>
        </div>
        <h4 className='title'>Happi Queue Jobs</h4>
        <div> 
        </div> 
      </div>
      <div className="timelinedashboard">
        <Row className="mt-1">
          <Col>
            <div className="fixTableHead">
              <Table responsive striped bordered hover>
                <thead>
                  <tr className="th-row">
                    <th>Name</th>
                    <th className="">IMEI Number</th>
                    <th className="">From Store</th>
                    <th className="">To Store</th>
                    <th className="">Created At</th>
                    <th>status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    return (
                      <tr key={index} className="tablerow">
                        <td>{item?.name}</td>
                        <td>

                      
                        <p className="m-0">{item?.imei_numbers?.[0]}</p>
                          </td>
                        <td>
                        
                        <p className="m-0">{item?.from_store}</p>
                         </td>
                        <td>
                        
                        <p className="m-0">{item?.to_store }</p>
                          
                          
                          </td>
                        <td>
                          {moment(item.created_at).format(
                            "MMM Do YYYY, h:mm:ss a"
                          )}
                        </td>
                     <td>{item?.status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className='d-flex justify-content-center'>
            <Pagination>
               
            {
                getPaginationItems().map((item,index)=> <Pagination.Item  key={index} active={item === currentPage} onClick={()=>{setCurrentPage(item)}} disabled={item === '...'}>{item}</Pagination.Item>)
            }
           
            </Pagination>
            </div>
            </div>
          </Col>
        </Row>
    </div>
    </>
  
  );
};

export default QueueJobs;