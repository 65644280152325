import React from 'react'
import { useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import "./Missedcustomers.scss";

//Receives missed customers data through redux state.
//Showing those data in both accordian and tables

const MissedCustomer = () => {
    const missiedCustomer = useSelector(state => state.profitability.missiedCustomer);
    return (
        <>
        <h3 className='text-center mt-2'>Missed Customers</h3>
        <Accordion defaultActiveKey="0">
            {
                [...new Set(missiedCustomer.map(item => item.branch_name))].map((item, index) => {
                    return (
                        <Accordion.Item eventKey={index}>
                            <Accordion.Header>{item}---<span>{missiedCustomer.filter(filterItem => filterItem.branch_name == item).length}</span></Accordion.Header>
                            <Accordion.Body>
                                <div class="table-responsive missedcustomers">
                                    <table class="table align-middle">
                                        <thead>
                                            <tr>
                                                <th>Brand Name</th>
                                                <th>Item Name</th>
                                                <th>Branch code</th>
                                                <th>Quantity</th>
                                                <th>MOP</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {missiedCustomer.filter(filterItem => filterItem.branch_name == item).map((item) => (
                                                <tr>
                                                    <td>{item.brand_name}</td>
                                                    <td>{item.item_name}</td>
                                                    <td>{item.branch_code}</td>
                                                    <td>{item.qty}</td>
                                                    <td>{item.mop}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                }
                )
            }
        </Accordion>
        </>
    )
}

export default MissedCustomer