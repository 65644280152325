import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import "react-toastify/dist/ReactToastify.css";
import "./Profitability.scss";
import { getBrands, getModals, getStores } from "../../redux/reducers/rules";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "react-vertical-timeline-component/style.min.css";
import Loader from "../../components/loader/loader";
// import Loader from "../../components/flagloader/Loader";
import Table from "react-bootstrap/Table";
import {
    setActiveTab,
    getMTD,
    getFTD,
    getMonthData,
    setModalTab,
    getProducts,
    getProfitsheets,
    getTransactions,
    getBrandsandModels,
    getAccessories,
    happicareList,
    getHappicare,
    setSearchProfitsheets,
    getAllProfitsheets,
    getAllASM,
    getAllStores,
    postCustomerDetails,
    getMTDRanking,
    getFTDRanking,
    getMonthDataRanking,
    getPDaysLdays,
    getAffordabilityData,
    getMissedCustomersData,
    getSyncTime,
    setSelectedDeviceItem
} from "../../redux/reducers/profitability";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { IoDocumentTextSharp } from "react-icons/io5";
import DataGrid from "react-data-grid";
import { Watermark } from "@hirohe/react-watermark";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import discount from "../../assets/icons8-discount.gif";
import expenses from "../../assets/expenses.png";
import grossprofit from "../../assets/grossprofit.png";
import netprofit from "../../assets/netprofit.jpg";
import gstimage from "../../assets/gst.png";
import profit1 from "../../assets/profit2.png";
import lost from "../../assets/loss.jpg";
import { debounce } from "lodash";
import { FaArrowLeft } from "react-icons/fa6";

import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { RxCrossCircled } from "react-icons/rx";
// import netprofit from "../../assets/netprofit.jpg"
import { FaSortAmountDownAlt } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import ProfitRanking from "./ProfitRanking";
import { useNavigate } from "react-router-dom";


const ProfitPlusDetails = () => {
    const selectedItem = useSelector(state => state.profitability.selectedItem);
    const accessoriesItem = useSelector(state => state.profitability.accessoriesItem)
    const allProfitsheets = useSelector(
        (state) => state.profitability.allProfitsheets
    );
    const transactionsData = useSelector(
        (state) => state.profitability.transactionsData
    );
    const empId = useSelector(state => state.profitability.empId);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedDevice, setSelectedDevice] = React.useState(selectedItem);
    const [searchResults, setSearchResults] = useState([]);
    const [discountValue, setDiscountValue] = useState();
    const [profit, setProfit] = useState(0);
    const [totalmop, setTotalmop] = useState(0);
    const [addedAccessories, setAddedAccessories] = useState(accessoriesItem);



    useEffect(() => {
        let totalprofit = 0;
        let totalmop = 0;
        addedAccessories?.length > 0 &&
            addedAccessories?.forEach((each) => {
                let result = Math.sign(parseInt(each.gp));
                let mopresult = Math.sign(parseInt(each.mop));
                if (result == 1) {
                    totalprofit = totalprofit + parseInt(each.gp);
                } else {
                    totalprofit = totalprofit - parseInt(each.gp);
                }
                if (mopresult == 1) {
                    totalmop = totalmop + parseInt(each.mop);
                } else {
                    totalmop = totalmop - parseInt(each.mop);
                }
            });
        if (discountValue == undefined) {
            totalprofit = parseInt(totalprofit) - 0;
            totalmop = parseInt(totalmop) - 0;
        } else {
            totalprofit = parseInt(totalprofit) - parseInt(discountValue);
            totalmop = parseInt(totalmop) - parseInt(discountValue);
        }
        setProfit(totalprofit);
        setTotalmop(totalmop);
    }, [addedAccessories, discountValue])

    const check = (value) => {
        const isChecked = addedAccessories.some(
            (item) => item?.item == value?.item
        );
        return isChecked;
    };

    const clearSearch = () => {
        setSearchTerm("");
        setSearchResults([]);
    };

    const addItem = (item) => {
        setSelectedDevice((prevValues) => {
            let exists = selectedDevice.some((obj) => obj.item == item.item);
            if (!exists) {
                return [...prevValues, item];
            } else {
                return prevValues;
            }
        });
    };

    function sendMessageToReactNative(message) {
        window.history.back()
        window.ReactNativeWebView?.postMessage("navigatingback");
      }


    const handleSearchChange = (value) => {
        if (value?.length != 0) {
            const filteredData = allProfitsheets?.filter((item) =>
                item.item.toLowerCase().includes(value?.toLowerCase())
            );
            setSearchResults(filteredData);
        } else {
            setSearchResults([]);
        }
    };

    const handleCheckboxChange = (e) => {
        let newValue = e.target._wrapperState.initialValue;
        setAddedAccessories((prevValues) => {
            let exists = prevValues.some((obj) => obj.item == newValue.item);
            if (!exists) {
                return [...prevValues, newValue];
            } else {
                return prevValues.filter((val) => val.item !== newValue.item);
            }
        });
    };

    const checkicon = (item) => {
        let checkvalue =
            selectedDevice?.length > 0 &&
            selectedDevice?.some((device) => device.item == item.item);
        return (
            <>
                {checkvalue ? (
                    <div style={{ color: "rgba(250, 144, 18, 0.66)" }}>
                        {" "}
                        <FiMinusCircle />{" "}
                    </div>
                ) : (
                    <div style={{ color: "rgba(250, 144, 18, 0.66)" }}>
                        {" "}
                        <FiPlusCircle onClick={() => addItem(item)} />{" "}
                    </div>
                )}
            </>
        );
    };

    const gapColor = (value, type) => {
        let result = Math.sign(value);
        return (
            <>
                {type == "Flashgard" ||
                    type == "Mobiles" ||
                    type == "featureMobiles" ? (
                    <div style={{ color: result != 1 ? "red" : "green" }}>
                        {new Intl.NumberFormat("en-IN").format(value)}
                    </div>
                ) : (
                    <div style={{ color: result != 1 ? "red" : "green" }}>
                        &#8377;{new Intl.NumberFormat("en-IN").format(value)}
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            <Container fluid>
                <Row className="justify-content-center mt-3">
                    <Col lg={3}>
                        <Watermark text={`-${empId}-`}>
                        <FaArrowLeft className="fs-5 my-2 ps-1" onClick={sendMessageToReactNative} />
                            <div className="d-flex mb-2 ps-3">
                                <h5 className="text-center ww-ctrl ps-5 m-0 acc-header">
                                    {" "}
                                    ITEM
                                </h5>
                                <h5 className="w-ctrl-2 text-center ps-3 pe-0  m-0 acc-header">
                                    MOP
                                </h5>
                                <h5 className="text-center m-0 w-ctrl-3 pe-5 acc-header">
                                    GP
                                </h5>
                            </div>
                            {selectedDevice?.length > 0 &&
                                selectedDevice?.map((eachitem, index) => {
                                    return (
                                        <>
                                            {" "}
                                            <div className="w-100 p-2 border-bottom border-secondary-subtle">
                                                <div className="d-flex w-100">
                                                    <h6 className="m-0 w-ctrl d-flex gap-2">
                                                        <input
                                                            type="checkbox"
                                                            value={eachitem}
                                                            checked={check(eachitem)}
                                                            onChange={handleCheckboxChange}
                                                        />{" "}
                                                        {eachitem.item}
                                                    </h6>{" "}
                                                    <h6 className="m-0 px-1 w-ctrl-2">
                                                        &#8377;{eachitem.inr_mop}
                                                    </h6>{" "}
                                                    <h6 className="m-0 px-1 w-ctrl-3">
                                                        &#8377;{eachitem.inr_gp}
                                                    </h6>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}

                            <div className="position-relative my-2">
                                <CiSearch className="position-absolute search" />
                                <input
                                    type="text"
                                    placeholder="search with Name"
                                    className="w-100 ps-4"
                                    value={searchTerm}
                                    onChange={(e) => {handleSearchChange(e.target.value);setSearchTerm(e.target.value)}}
                                />
                                <RxCrossCircled
                                    className="position-absolute clear"
                                    onClick={clearSearch}
                                />
                            </div>
                            {searchResults?.map((eachdevice, index) => (
                                <div className="w-100 py-2 border-bottom border-secondary-subtle">
                                    <div className="d-flex w-100">
                                        <h6 className="m-0 w-ctrl d-flex gap-2">
                                            {/* <input
                                            type="checkbox"
                                            // checked={check(eachdevice)}
                                            value={eachdevice}
                                            onChange={handleCheckboxChange}
                                          />{" "} */}

                                            {/* {selectedDevice?.length>0 && selectedDevice?.some((device) => device.item == eachdevice.item) ?  ( <div>  <FiMinusCircle /> </div>):  (<div> <FiPlusCircle onClick = {() => addItem(eachdevice)}/> </div>)} */}
                                            {checkicon(eachdevice)}

                                            {eachdevice.item}
                                        </h6>{" "}
                                        <h6 className="m-0 px-1 w-ctrl-2">
                                            &#8377;{eachdevice.inr_mop}
                                        </h6>{" "}
                                        <h6 className="m-0 px-1 w-ctrl-3">
                                            &#8377;{eachdevice.inr_gp}
                                        </h6>
                                    </div>
                                </div>
                            ))}

                            <div className="p-2  d-flex align-items-center justify-content-between">
                                <h6>D</h6>
                                <input
                                    type="number"
                                    placeholder="Enter Discount"
                                    value={discountValue}
                                    className="ml-auto"
                                    onChange={(e) => {
                                        setDiscountValue(
                                            e.target.value == "" ? 0 : parseInt(e.target.value)
                                        );
                                    }}
                                    style={{
                                        maxWidth: "80px",
                                        // marginRight: "48px",
                                        textAlign: "right",
                                    }}
                                />
                            </div>
                            {/* <p style = {{fontSize:"10px",marginLeft:"150px"}}>Maximum Discount : &#8377;{maxDiscountValue}</p> */}
                            {/* <div className="d-flex p-2 align-items-center justify-content-between mop">
                <h6 className="m-0">
                  Max Discount : 
                </h6>
                <h6 className="m-0 pe-5">&#8377;{maxDiscountValue}</h6>
              </div> */}
                            <div className="d-flex p-2 align-items-center justify-content-between mop">
                                <h6 className="m-0">
                                    Total MOP<span>(with Discount)</span>
                                </h6>
                                <h6 className="m-0 pe-5">&#8377;{totalmop}</h6>
                            </div>
                            <div className="d-flex p-2 align-items-center justify-content-between">
                                <h6 className="m-0">Margin</h6>
                                <h6 className="m-0  pe-5">&#8377;{profit}</h6>
                            </div>
                            <div className="d-flex p-2 align-items-center justify-content-between">
                                <h6 className="m-0">T</h6>
                                <h6 className="m-0 pe-5">
                                    &#8377;
                                    {Math.trunc((profit / 1.18) * 0.18 * 0.9).toFixed(0)}
                                </h6>
                            </div>
                            <div className="d-flex p-2 align-items-center justify-content-between mop">
                                <h6 className="m-0">
                                    P/L <span>(Without Expenses)</span>
                                </h6>
                                <h6 className="m-0 pe-5">
                                    {gapColor(
                                        profit -
                                        Math.trunc((profit / 1.18) * 0.18 * 0.9).toFixed(0)
                                    )}
                                </h6>
                            </div>
                        </Watermark>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ProfitPlusDetails