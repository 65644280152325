import React from 'react'
import noData from '../../assets/no_data.png'

//Re-usable component for displaying no data found image.

const NoDataFound = () => {
  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100">
    <img src={noData} alt="no data found" title="no data found" />
  </div>
  )
}

export default NoDataFound