import React, { useState, useEffect } from 'react'
import { Wheel } from 'react-custom-roulette';
import './spinwheel.scss'
import Select from 'react-select'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';




const data = [
  { option: 'Smart Watch', style: { backgroundColor: 'green', textColor: 'white' } },
  { option: 'Neck Band' },
  { option: 'Screen Guard' },
  { option: "TWS" },
  { option: "Quick Heal" },
  { option: "Ear phones" },
  { option: "Un Lucky"}

]

function RifahSpin() {
  const [mustSpin, setMustSpin] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [enableSpin,setEnableSpin] = useState(false);
  const [showSuccessModal,setShowSuccessModal]=useState(false);
  const [showOption,setShowOption]=useState(0);
  const [disableShowModalButton,setDisableShowModalButton]=useState(false);
  const [submitBtnLoader,setSubmitBtnLoader]=useState(false);
  const [mobileError,setMobileError]=useState(false);
  const[collegeOptions, setCollegeOptions] = useState([]);
  const [emailerror,setEmailErorr]=useState(false)



  useEffect(() => {

    const fetchColleges = async () => {
  
      try {
  
        const response = await fetch('https://dev-services.happimobiles.com/tptoffers/getColleges');
  
        const result = await response.json();
  
        if (result?.status && result?.data[0]?.colleges_list) {
  
          // Map the colleges_list to the format required by react-select
  
          const colleges = result.data[0].colleges_list.map((college) => ({
  
            value: college,
  
            label: college,
  
          }));
  
          setCollegeOptions(colleges);
  
        } else {
  
  
        }
  
      } catch (error) {
  
  
      }
  
    };
   
    fetchColleges();
  
  }, []);
  
   
  

  const validateForm = () => {
    const newErrors = {};

    if (!userDetails.name) {
      newErrors.name = 'Name is required';
    }

    const phoneRegex = /^[6-9]\d{9}$/;
    if (!userDetails.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!phoneRegex.test(userDetails.phone)) {
      newErrors.phone = 'Phone number is invalid';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userDetails.email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(userDetails.email)) {
      newErrors.email = 'Email is invalid';
    }
    // if (!userDetails.gender) {
    //   newErrors.gender = 'Gender is required';
    // }
    // if (!userDetails.college) {
    //   newErrors.college = 'College is required';
    // }
    if (!userDetails.brand) {
      newErrors.brand = 'Brand is required';
    }
    if (!userDetails.price) {
      newErrors.price = 'Price is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


const onSpinCompleteSubmitData=async ()=>{
setShowSuccessModal(true);
setDisableShowModalButton(true)
let submitUserDetails=await fetch('https://dev-services.happimobiles.com/tptoffers/tunileads', {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({
        "name":userDetails.name,
        "phone": userDetails.phone,
        "email": userDetails.email,
        "prize": userDetails.wonItem,
        "college": userDetails.college,
        "gender": userDetails.gender,
        "brand": userDetails.brand,
        "budget": userDetails.price,
        "utm_campaign": '',
        "utm_medium": '',
        "utm_source": '',
        "type": 'rifah'
    })})
    let res=await submitUserDetails.json();
    let result=await res;
    if(result.status){
      setDisableShowModalButton(false)
    }
  }

  const validatePhone = async () => {
    setSubmitBtnLoader(true)
    let api = 'https://dev-services.happimobiles.com/tptoffers/leads';
    let gettingData = await fetch(api, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "phone": userDetails?.phone,
        "email": userDetails.email,
      })
    })
    let res=await gettingData.json()
    let result =await res;
    if(result.status){
      setEnableSpin(true);
      getSpinnerValue()
    }
    else{
      setMobileError(true);
      setEmailErorr(true);
      // toast("Mobile Number Already Registered")
    }
    setSubmitBtnLoader(false)
  }

  // let  body={
  //       "name": this.nameReq,
  //       "phone": this.phoneNo,
  //       "email": this.email,
  //       "prize": this.wonItem,
  //       "college": this.college,
  //       "gender": this.gender,
  //       "brand": this.brand,
  //       "budget": this.budget,
  //       "utm_campaign": '',
  //       "utm_medium": '',
  //       "utm_source": '',
  //       "type": ''

  // }
  // const collegeOptions = [
  //   { value: "KMM Science and Technology - Tirupati", label: "KMM Science and Technology - Tirupati" },
  //   { value: "Samata Degree College - Vizag", label: "Samata Degree College - Vizag" },
  //   { value: "Government City College - Hyderabad", label: "Government City College - Hyderabad" },
  // ]
  const brandOptions = [
    { value: "Samsung", label: "Samsung" },
    { value: "Nokia", label: "Nokia" },
    { value: "Realme", label: "Realme" },
    { value: "Iphone", label: "Iphone" },
    { value: "Redmi", label: "Redmi" },
    { value: "OPPO", label: "OPPO" },
    { value: "VIVO", label: "VIVO" },
  ]
  const mobileOptions = [
    { value: "10k - 15k", label: "10k - 15k" },
    { value: "15k - 20k", label: "15k - 20k" },
    { value: "20k - 30k", label: "20k - 30k" },
    { value: "30k - 40k", label: "30k - 40k" },
    { value: "40k - 50k", label: "40k - 50k" },
    { value: "Above 50k", label: "Above 50k" },
  ]
  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
  ]

  const submitData = () => {

    if (validateForm()) {
      validatePhone();
    }

  }
 
const getSpinnerValue=async ()=>{
  let getData=await fetch(' https://dev-services.happimobiles.com/tptoffers/rifaExproProbability');
  let res=await getData.json()
  let result =await res
  if(res?.data=='smart watch'){
    setShowOption(0);
    setUserDetails(prev=>({...prev,wonItem:"smart watch"}))
  }
  if(res?.data=='TWS'){
    setUserDetails(prev=>({...prev,wonItem:"TWS"}));
    setShowOption(3);

  } 
  if(res?.data=='Screen Guard'){
    setUserDetails(prev=>({...prev,wonItem:"Screen Guard"}));
    setShowOption(2);
  } 
  if(res?.data=='Quick Heal - Antivirus') {
    setUserDetails(prev=>({...prev,wonItem:"Quick Heal - Antivirus"}));
    setShowOption(4);
  }
  if(res?.data=='Neck Band'){
    setUserDetails(prev=>({...prev,wonItem:"Neck Band"}));
    setShowOption(1);
  } 
  if(res?.data=='Ear phones'){
    setUserDetails(prev=>({...prev,wonItem:"Ear phones"}))
    setShowOption(5);
  } 
}

const onPrizeSucess=()=>{
setShowSuccessModal(false);
setUserDetails({});setEnableSpin(false);
window.location.reload()
}

  return (
    <>
  
      <div className='wh-p d-flex align-items-center justify-content-center flex-column p-3'>
        <h3 className='text-white fw-bold'>Spin the Wheel to Win Big At Happi Mobiles</h3>
        <div>
          <div className='row'>
            <div className='col-md-4 p-3'>
              <input type="text" placeholder='Name' className={`w-100 form-control ${errors.name ? 'is-invalid' : ''}`}
                name="name"
                value={userDetails?.name}
                onChange={(e) => setUserDetails(prev => ({ ...prev, name: e.target.value }))}
              />
              {errors.name && <div className="invalid-feedback">{errors.name}</div>}
            </div>
            <div className='col-md-4 p-3' >
              <input type="text" placeholder='Phone Number' className={`w-100 form-control ${errors.phone ? 'is-invalid' : ''}`}
                name="phone"
                value={userDetails?.phone}
                maxLength={10}
                onChange={(e) => {setMobileError(false);setUserDetails(prev => ({ ...prev, phone: e.target.value }))}}
              />
              {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
              {mobileError && <div className="invalid-feedback">Mobile Number or Email Already Registered</div>}


            </div>
            <div className='col-md-4 p-3'>
              <input type="text" placeholder='Email' className={`w-100 form-control ${errors.email ? 'is-invalid' : ''}`}
                name="email"
                value={userDetails?.email}
                onChange={(e) =>{setEmailErorr(false); setUserDetails(prev => ({ ...prev, email: e.target.value }))}}
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
              {emailerror && <div className="invalid-feedback">Email or Mobile Number Already Registered</div>}

            </div>
            {/* <div className='col-md-4 p-3  z-4 position-relative'>
              <Select options={genderOptions}
                placeholder="Select Gender" 
                onChange={(e) => setUserDetails(prev => ({ ...prev, gender: e.value }))}
              />
              {errors.gender && <div className="invalid-feedback z--1">{errors.gender}</div>}

            </div> */}
            {/* <div className='col-md-4 p-3'>
              <Select options={collegeOptions}
                placeholder="Select College"
                onChange={(e) => setUserDetails(prev => ({ ...prev, college: e.value }))}
              />
              {errors.college && <div className="invalid-feedback z--2">{errors.college}</div>}

            </div> */}
            <div className='col-md-4 p-3'>
              <Select options={brandOptions}
                placeholder="Select Brand"
                onChange={(e) => setUserDetails(prev => ({ ...prev, brand: e.value }))}
              />
              {errors.brand && <div className="invalid-feedback z--3">{errors.brand}</div>}

            </div>
            <div className='col-md-4 p-3'>
              <Select options={mobileOptions}
                placeholder="Select Budget"
                onChange={(e) => setUserDetails(prev => ({ ...prev, price: e.value }))}
              />
              {errors.price && <div className="invalid-feedback z--4">{errors.price}</div>}

            </div>
            <div className='col-md-4 p-3'>
              <Button variant='dark' disabled={enableSpin||submitBtnLoader} onClick={submitData} className='w-100'>
               {submitBtnLoader?<Spinner animation="border" />:"Submit"} 
                </Button>
            </div>

          </div>

        </div>
       {!enableSpin&&<h4 className='text-white fw-bold'>Spin Here and Win a Prize</h4>} 
        {
          true && <>
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={showOption}
              data={data}
              backgroundColors={['#fb9013', '#ffffff']}
              textColors={['#ffffff','#fb9013']}
              onStopSpinning={onSpinCompleteSubmitData}
              outerBorderWidth="1"
              innerBorderWidth="1"
              radiusLineWidth="2"
              offsetX="2"
              offsetY="2"
            />
            <Button variant="success"  disabled={!enableSpin} onClick={() => setMustSpin(true)}>Spin Prize</Button>

          </>
        }
      </div>

      <Modal show={showSuccessModal} onHide={()=>setShowSuccessModal(false)} 
      size="sm"
      backdrop="static"
      keyboard={false}
      centered
        >
        <Modal.Header>
          <Modal.Title>Congratulations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column align-items-center justify-content-center'>
          <p>Congrats You Won <span>{userDetails?.wonItem}</span></p>
<Button variant="secondary" disabled={disableShowModalButton} onClick={onPrizeSucess}>
    Ok
  </Button>
          </div>
      
        </Modal.Body>
        <Modal.Footer>
         
         
        </Modal.Footer>
      </Modal>
      <ToastContainer />

    </>
  );
}

export default RifahSpin;
