import React, { useState, useEffect } from 'react'
import { Wheel } from 'react-custom-roulette';
import './pushpa.scss'
import Select from 'react-select'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import bhaiImg from '../../assets/PushpaBhai.jpeg'



const data = [
    { option: 'Smart Watch', style: { backgroundColor: 'green', textColor: 'white' } },
    { option: 'Neck Band' },
    { option: 'Screen Guard' },
    { option: "TWS" },
    { option: "Quick Heal" },
    { option: "Ear phones" },
    { option: "Un Lucky" }

]

function PushpaStickerEvent() {
    const [userDetails, setUserDetails] = useState({});
    const [errors, setErrors] = useState({});
    const [enableSpin, setEnableSpin] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [disableShowModalButton, setDisableShowModalButton] = useState(false);
    const [submitBtnLoader, setSubmitBtnLoader] = useState(false);
    const [mobileError, setMobileError] = useState(false);


    useEffect(() => {

        document.title="Pushpa"

    }, []);




    const validateForm = () => {
        const newErrors = {};
        if (!userDetails.name) {
         newErrors.name = 'Name is required';
        }

        const phoneRegex = /^[6-9]\d{9}$/;
        if (!userDetails.phone) {
            newErrors.phone = 'Phone number is required';
        } else if (!phoneRegex.test(userDetails.phone)) {
            newErrors.phone = 'Phone number is invalid';
        }

        if (!userDetails.store) {
            newErrors.store = 'Store is required';
        }
        if (!userDetails.sticker) {
            newErrors.sticker = 'Sticker is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const submitData = async () => {
        let result;
        if (validateForm()) {
        try{
            let {data}=await axios.post('https://dev-services.happimobiles.com/api/pushpa',userDetails,{headers:{ "content-type":"application/json"}})
            toast.success("Thank you for submitting your details!✅")
            setTimeout(()=>{
                window.location.assign("https://www.happimobiles.com/our-stores");

            },2000)


        }
        catch(e){
            toast.error(e.response.data.message)
        }
       
    }
    }

    const pushpaOptions = [{ label: "Pushpa2 [A]", value: "Pushpa2 [A]"}, { label: "Pushpa2 [B]", value: "Pushpa2 [B]"},{ label: "Pushpa2 [C]", value: "Pushpa2 [C]"},{ label: "Pushpa2 [D]", value: "Pushpa2 [D]"},{ label: "Pushpa2 [E]", value: "Pushpa2 [E]"},]
    const storeOptions = [
        { "label": "AMEERPET", "value": "AMEERPET" },
        { "label": "ASRAONAGAR", "value": "ASRAONAGAR" },
        { "label": "ATTAPUR", "value": "ATTAPUR" },
        { "label": "BODUPPAL", "value": "BODUPPAL" },
        { "label": "CHANDANAGAR", "value": "CHANDANAGAR" },
        { "label": "CHINTAL", "value": "CHINTAL" },
        { "label": "DILSUKHNAGAR", "value": "DILSUKHNAGAR" },
        { "label": "ECIL", "value": "ECIL" },
        { "label": "KHARKHANA", "value": "KHARKHANA" },
        { "label": "KHARMANGHAT", "value": "KHARMANGHAT" },
        { "label": "KUKATPALLY", "value": "KUKATPALLY" },
        { "label": "LANGERHOUSE 2", "value": "LANGERHOUSE 2" },
        { "label": "MADHAPUR", "value": "MADHAPUR" },
        { "label": "MALKAJGIRI", "value": "MALKAJGIRI" },
        { "label": "RAMANTHAPUR", "value": "RAMANTHAPUR" },
        { "label": "RTC X ROAD", "value": "RTC X ROAD" },
        { "label": "S.D.ROAD", "value": "S.D.ROAD" },
        { "label": "S.R.NAGAR", "value": "S.R.NAGAR" },
        { "label": "SANTOSHNAGAR", "value": "SANTOSHNAGAR" },
        { "label": "SAROORNAGAR", "value": "SAROORNAGAR" },
        { "label": "SHAMSHABAD", "value": "SHAMSHABAD" },
        { "label": "SHAPUR", "value": "SHAPUR" },
        { "label": "SUCHITRA", "value": "SUCHITRA" },
        { "label": "UPPAL", "value": "UPPAL" },
        { "label": "VANASTALIPURAM", "value": "VANASTALIPURAM" }
    ]



    const onPrizeSucess = () => {
        setShowSuccessModal(false);
        setUserDetails({}); setEnableSpin(false);
        window.location.reload()
    }


    return (
        <>

            <div className='wh-p p-3'>
                <div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='row'>
                            <img src={bhaiImg} height={"100%"} width={"100%"} style={{objectFit:"contain"}}/>
                            <div className='col-md-6 p-3'>
                                <input type="text" placeholder='Name' className={`w-100 form-control ${errors.name ? 'is-invalid' : ''}`}
                                    name="name"
                                    value={userDetails?.name||""}
                                    onChange={(e) => setUserDetails(prev => ({ ...prev, name: e.target.value }))}
                                />
                                {errors.name&&<p className='text-danger fw-bold'>{errors.name}</p>}
                            </div>
                            <div className='col-md-6 p-3' >
                                <input type="text" placeholder='Phone Number' className={`w-100 form-control ${errors.phone ? 'is-invalid' : ''}`}
                                    name="phone"
                                    value={userDetails?.phone||""}
                                    maxLength={10}
                                    onChange={(e) => { setMobileError(false); setUserDetails(prev => ({ ...prev, phone: e.target.value })) }}
                                />
                            {errors.phone&&<p className='text-danger fw-bold'>{errors.phone}</p>}
                            </div>
                            <div className='col-md-6 p-3'>
                                <Select options={pushpaOptions}
                                    placeholder="Select Image"
                                    onChange={(e) => setUserDetails(prev => ({ ...prev, sticker: e.value }))}
                                />
                            {errors.sticker&&<p className='text-danger fw-bold'>{errors.sticker}</p>}

                            </div>

                            <div className='col-md-6 p-3'>
                                <Select options={storeOptions}
                                    placeholder="Select Store"
                                    onChange={(e) => setUserDetails(prev => ({ ...prev, store: e.value }))}
                                />
                            {errors.store&&<p className='text-danger fw-bold'>{errors.store}</p>}


                            </div>

                            <div className='d-flex justify-content-center align-items-center'>
                                <Button variant='dark' disabled={enableSpin || submitBtnLoader} onClick={submitData} className='w-25'>
                                    {submitBtnLoader ? <Spinner animation="border" /> : "Submit"}
                                </Button>
                            </div>

                        </div>
                    </div>


                </div>

            </div>

            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}
                size="sm"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Congratulations</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <p>Congrats You Won <span>{userDetails?.wonItem}</span></p>
                        <Button variant="secondary" disabled={disableShowModalButton} onClick={onPrizeSucess}>
                            Ok
                        </Button>
                    </div>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </>
    );
}

export default PushpaStickerEvent;
