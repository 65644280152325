import axios from "axios";
import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Container, Row, Col } from "react-bootstrap";
import "./timeline.scss";
// import "./timeline.css";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';




import moment from "moment";

//Re-usable compoenent that receives data through props and displays it.

const TimelineDashboard = ({data=[]}) => {
  const getTimeDiff = (time) => {
    return (
      <p className={(time?.minutes > 0&&time?.minutes<=5&&time?.hours<1)?`m-0 darkgreen`:(time?.minutes > 0&&time?.minutes>5&&time?.minutes<=10&&time?.hours<1)?"m-0 lightgreen":(time?.minutes >0&&time?.minutes>10&&time?.minutes<=30&&time?.hours<1)?"m-0 darkyellow":(time?.minutes >0&&time?.minutes>30&&time?.minutes<=59&&time?.hours<1)?"m-0 lightred":(time?.minutes==0&&time?.hours==0)?"m-0 darkgreen":"m-0 darkred"}>
        {time?.hours > 0 && `${time?.hours}h`}{" "}
        {time?.minutes > 0 && `${time?.minutes}m`}{" "}
        {time?.seconds > 0 && `${time?.seconds}s`}{" "}
      </p>
    );
  };
  return (
    <div className="timelinedashboard">
        <Row className="mt-1">
          <Col>
            <div className="fixTableHead">
              <Table responsive striped bordered hover>
                <thead>
                  <tr className="th-row">
                    <th className="brand-width">Brand</th>
                    <th className="th-width">From Store</th>
                    <th className="th-width">To Store</th>
                    <th className="created-width">Created At</th>
                    <th>Time Taken</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    return (
                      <tr key={index} className="tablerow">
                        <td>

                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{item?.model}
                        </Tooltip>}>
                        <p className="m-0">{item.model?.length>15?`${item?.model?.slice(0,25)}...`:item?.model}</p>
                           </OverlayTrigger>
                          </td>
                        <td>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item?.from_store_name}
                        </Tooltip>}>
                        <p className="m-0">{item?.from_store_code}</p>
                           </OverlayTrigger>
                         </td>
                        <td>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{item?.to_store_name}
                        </Tooltip>}>
                        <p className="m-0">{item?.to_store_code}</p>
                           </OverlayTrigger>
                          
                          </td>
                        <td>
                          {moment(item.created_at).format(
                            "MMM Do YYYY, h:mm:ss a"
                          )}
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {
                              (item?.created_to_approve_time_diff?.hours > 0 ||
                                item?.created_to_approve_time_diff?.minutes > 0 ||
                                item?.created_to_approve_time_diff?.seconds > 0)&&<>
                            <span className="boxsh">Created</span>
                            {getTimeDiff(item?.created_to_approve_time_diff)}
                            <span className="boxsh">Approved</span>
                                </>
                            }
                             {
                              (item?.created_to_approve_time_diff?.hours ==0 &&
                                item?.created_to_approve_time_diff?.minutes ==0 &&
                                item?.created_to_approve_time_diff?.seconds ==0)&&<>
                                <span className="boxsh">Created</span>
                                <div className="loader-anim">
                                </div>
                                </>
                            }
                             {
                              ((item?.created_to_approve_time_diff?.hours >0 ||
                                item?.created_to_approve_time_diff?.minutes >0 ||
                                item?.created_to_approve_time_diff?.seconds >0)&&(item?.approved_to_apx_push_diff?.hours == 0 &&
                                  item?.approved_to_apx_push_diff?.minutes ==0 &&item?.approved_to_apx_push_diff?.seconds ==0))&&<div className="loader-anim">
                                </div>
                            }
                            
                           
                            {(item?.approved_to_apx_push_diff?.hours > 0 ||
                              item?.approved_to_apx_push_diff?.minutes > 0 ||
                              item?.approved_to_apx_push_diff?.seconds > 0)&&
                              <>
                                {getTimeDiff(item?.approved_to_apx_push_diff)}
                                <span className="boxsh">Pushed To APX</span>
                              </>
                            }
                             {((item?.approved_to_apx_push_diff?.hours>0 ||
                              item?.approved_to_apx_push_diff?.minutes>0 ||
                              item?.approved_to_apx_push_diff?.seconds>0)&& (item?.created_to_approve_time_diff?.hours > 0 ||
                                item?.created_to_approve_time_diff?.minutes > 0 ||
                                item?.created_to_approve_time_diff?.seconds > 0)&&(item?.apx_to_intransit_diff?.hours ==0&&
                                  item?.apx_to_intransit_diff?.minutes ==0&&
                                  item?.apx_to_intransit_diff?.seconds ==0))&&
                              <div className="loader-anim"> 
                              </div>
                            }
                            {(item?.apx_to_intransit_diff?.hours > 0 ||
                              item?.apx_to_intransit_diff?.minutes > 0 ||
                              item?.apx_to_intransit_diff?.seconds > 0) && (
                              <>
                                {getTimeDiff(item?.apx_to_intransit_diff)}
                                <span className="boxsh">In transit</span>
                              </>
                            )}
                             {((item?.approved_to_apx_push_diff?.hours>0 ||
                              item?.approved_to_apx_push_diff?.minutes>0 ||
                              item?.approved_to_apx_push_diff?.seconds>0)&& (item?.created_to_approve_time_diff?.hours > 0 ||
                                item?.created_to_approve_time_diff?.minutes > 0 ||
                                item?.created_to_approve_time_diff?.seconds > 0)&&(item?.apx_to_intransit_diff?.hours >0||
                                  item?.apx_to_intransit_diff?.minutes >0||
                                  item?.apx_to_intransit_diff?.seconds>0)&&(item?.intransit_to_delivered_diff?.hours ==0&&
                                    item?.intransit_to_delivered_diff?.minutes==0 &&
                                    item?.intransit_to_delivered_diff?.seconds ==0))&&
                              <div className="loader-anim"> 
                              </div>
                            }

                            {(item?.intransit_to_delivered_diff?.hours > 0 ||
                              item?.intransit_to_delivered_diff?.minutes > 0 ||
                              item?.intransit_to_delivered_diff?.seconds > 0) && (
                              <>
                                {getTimeDiff(item?.intransit_to_delivered_diff)}
                                <span className="boxsh">Delivered</span>
                              </>
                            )}

                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
    </div>
  );
};

export default TimelineDashboard;